import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Barcode from "react-barcode";
import { shape, string, arrayOf, func, bool } from "prop-types";
import { ButtonBase, CircularProgress } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { logout } from "../../actions/user";
import AndroidIcon from "../../assets/google-play-badge.png";
import IOSLogo from "../../assets/ios_badge.js";
import {
  getUserSelector,
  getMyClubsSelector,
  getAllClubsSelector,
  getUseClubBarcodeFlagSelector,
  getIsAuthenticated,
  getShowFavoriteStoresModalSelector,
} from "../../utils/selectors";
import { getNorwegianDay } from "../../utils/methods";
import Requester from "../../api/Requester";
import ClubPickModal from "../../components/ClubPickModal/ClubPickModal";
import BarcodeModal from "../../components/BarcodeModal/BarcodeModal";
import { ActionCreators } from "../../actions";
import StorePickModal from "../../components/StorePickModal/StorePickModal";
import Loader from "../../components/Loader/Loader";
import {
  setShouldFollowStore,
  setSuggestedStoresToFollow,
  setIsFetchingSuggestedStores,
} from "../../actions/user";

import "./styles.scss";

function Member({
  user,
  setMyClubs,
  setAllClubs,
  allClubs,
  myClubs,
  enabledBarcodesFeature,
  setUserProfile,
  isAuthenticated,
  showFavoriteStoresModal,
  setShowFavoriteStoreModal,
}) {
  const [showClubModal, setShowClubModal] = useState(false);
  const [showBarcodeModal, setShowBarcodeModal] = useState(false);
  const [selectedClub, setSelectedClub] = useState({});
  const [barcodeValue, setBarcodeValue] = useState("");
  const [showEmployeeCard, setShowEmployeeCard] = useState(false);
  const [favoriteStores, setFavoriteStores] = useState([]);
  const [loading, setLoading] = useState(false);

  const isUserFindingStoreSelf = useSelector(
    (state) => state?.userReducer?.userFindingStoreSelf
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getMyClubs = async () => {
    try {
      const res = await Requester.getMyClubs();
      setMyClubs(res);
    } catch (err) {
      if (err === "Feilet fornying av login token. Prøv å logge inn på nytt.") {
        // message is shown in getMyClubsCall
        toast.error(err);
        dispatch(logout());
        await Requester.logout().then(() => {
          navigate("/");
        });
      } else {
        toast.error("Klarte ikke å hente mine klubber...");
        dispatch(logout());
        await Requester.logout().then(() => {
          navigate("/");
        });
      }
    }
  };

  const getMe = async () => {
    setLoading(true);
    try {
      await Requester.getMe().then(async (res) => {
        setUserProfile(res);
        if (res.should_follow_store === 1 && !isUserFindingStoreSelf) {
          dispatch(setShouldFollowStore(true));

          dispatch(setIsFetchingSuggestedStores(true));
          const suggestedStores = await Requester.getMyFollowStoresSuggestion();

          if (suggestedStores?.length > 0) {
            dispatch(setSuggestedStoresToFollow(suggestedStores));
          }
          if (suggestedStores?.length === 0) {
            dispatch(setSuggestedStoresToFollow([]));
          }
          setTimeout(() => dispatch(setIsFetchingSuggestedStores(false)), 500);
        }
      });
    } catch (err) {
      if (err === "Feilet fornying av login token. Prøv å logge inn på nytt.") {
        // message is shown in getMyClubsCall
        dispatch(logout());
        await Requester.logout().then(() => {
          navigate("/");
        });
      } else {
        toast.error("Klarte ikke å hente oppdatert profil...");
      }
    } finally {
      setLoading(false);
      setTimeout(() => dispatch(setIsFetchingSuggestedStores(false)), 500);
    }
  };

  const getAllClubs = async () => {
    setLoading(true);
    try {
      const data = await Requester.getAllClubs();
      setAllClubs(data);
    } catch {
      toast.error("Klarte ikke å hente alle klubber...");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetch user profile after my store has been changed
    if (isAuthenticated) {
      getMe();
    }
  }, [showFavoriteStoresModal, isAuthenticated]);

  useEffect(() => {
    // set favorite stores on first render
    if (user && user?.store_follows) {
      setFavoriteStores(user.store_follows);
    }
  }, [user.store_follows, showFavoriteStoresModal, isAuthenticated]);

  useEffect(() => {
    if (myClubs && myClubs.length > 0 && isAuthenticated) {
      const employeeCardFound = myClubs.find((club) => club.id === 0);
      if (employeeCardFound) {
        setShowEmployeeCard(true);
      } else {
        setShowEmployeeCard(false);
      }
    }
  }, [myClubs]);

  useEffect(() => {
    // update list when comming back from modal
    // need a check on logout
    if (showClubModal === false && isAuthenticated) {
      getMyClubs();
      getAllClubs();
    }
  }, [showClubModal]);

  useEffect(() => {
    if (
      enabledBarcodesFeature === true &&
      user &&
      user.intersport_membership_number
    ) {
      setBarcodeValue(user.intersport_membership_number.toString());
    } else if (
      enabledBarcodesFeature === false &&
      user &&
      user.membership_number
    ) {
      setBarcodeValue(user.membership_number.toString());
    }
  }, [enabledBarcodesFeature, user]);

  const weekday = moment(Date.now()).isoWeekday();
  const NOweekday = getNorwegianDay(weekday);

  const listClubs = () => {
    if (myClubs && myClubs.length > 0) {
      return myClubs.map((club) => {
        if (club.id && club.id > 0) {
          return (
            <div key={club.name} className="myClublistItem">
              <ButtonBase
                onClick={() => handleClubClick(club)}
                disabled={!enabledBarcodesFeature}
              >
                <div className="clubTitleText">{club.name}</div>
              </ButtonBase>
              {enabledBarcodesFeature ? (
                <ButtonBase onClick={() => handleClubClick(club)}>
                  <VisibilityIcon color="primary" />
                </ButtonBase>
              ) : null}
            </div>
          );
        }
        return null;
      });
    }
    return null;
  };

  const showFavoriteStore = () => {
    if (favoriteStores && favoriteStores.length > 0) {
      return favoriteStores.map((item) => {
        return (
          <div className="myClublistItem" key={item?.name}>
            <ButtonBase>
              <div className="storeTitleText">{item?.name}</div>
            </ButtonBase>
          </div>
        );
      });
    }
    return null;
  };

  const toggleShowClubs = () => {
    setShowClubModal(!showClubModal);
  };

  const toggleShowBarcodeModal = () => {
    setShowBarcodeModal(!showBarcodeModal);
  };

  const handleClubClick = (club) => {
    toggleShowBarcodeModal();
    setSelectedClub(club);
  };

  const formatMemberNumberForView = (memberNumber) => {
    if (memberNumber && enabledBarcodesFeature === true) {
      const prefix = memberNumber.substring(0, 4);
      const memberId = memberNumber.substring(4, 11);
      const affix = memberNumber.substring(11, 16);
      return `${prefix} ${memberId} ${affix}`;
    } else if (memberNumber && enabledBarcodesFeature === false) {
      return memberNumber;
    } else {
      return "";
    }
  };

  const renderEmployeeCard = () => {
    const employeeCards = myClubs.filter((club) => club.id === 0);

    const listEmployeeCards = () => {
      if (employeeCards && employeeCards.length > 0) {
        return employeeCards.map((club) => {
          return (
            <div key={club.name} className="myClublistItem">
              <ButtonBase
                onClick={() => handleClubClick(club)}
                disabled={!enabledBarcodesFeature}
              >
                <div className="clubTitleText">{club.name}</div>
              </ButtonBase>
              {enabledBarcodesFeature ? (
                <ButtonBase onClick={() => handleClubClick(club)}>
                  <VisibilityIcon color="primary" />
                </ButtonBase>
              ) : null}
            </div>
          );
        });
      }
    };

    if (showEmployeeCard) {
      return (
        <div className="employeeCardWrapper">
          <div className="employeeCardContainer">
            <div className="clubTitleSectionText">Mitt ansattkort</div>
          </div>
          {listEmployeeCards()}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="memberContent">
      <Loader cover state={loading} />
      {showFavoriteStoresModal ? (
        <StorePickModal
          showModal={showFavoriteStoresModal}
          toggleShowStores={setShowFavoriteStoreModal}
          myStores={favoriteStores}
          setMyStores={setFavoriteStores}
        />
      ) : null}
      {showClubModal ? (
        <ClubPickModal
          showModal={showClubModal}
          toggleShowClubs={toggleShowClubs}
          allClubs={allClubs}
          myClubs={myClubs}
          getMyClubsGlobal={getMyClubs}
          getAllClubsGlobal={getAllClubs}
          loading={loading}
        />
      ) : null}
      {showBarcodeModal ? (
        <BarcodeModal
          showModal={showBarcodeModal}
          toggleShowModal={toggleShowBarcodeModal}
          selectedClub={selectedClub}
        />
      ) : null}
      <div className="memberInfoContainer">
        <div className="dateContainer">
          <p className="weekday">{NOweekday}</p>
          <p className="date">{moment(Date.now()).format("DD.MM.YYYY")}</p>
        </div>
        {!showBarcodeModal ? (
          <div className="userBarcodeContainer">
            {barcodeValue ? (
              <Barcode
                value={barcodeValue}
                font="DIN-Pro"
                displayValue={false}
                width={3}
              />
            ) : null}
            <div className="barcodeTextValue">
              {barcodeValue.toString().length > 1
                ? formatMemberNumberForView(barcodeValue)
                : null}
            </div>
          </div>
        ) : (
          <CircularProgress size={26} className="loader"></CircularProgress>
        )}
        {myClubs && myClubs.length > 0 ? renderEmployeeCard() : null}
        {myClubs && myClubs.length > 0 ? (
          <div className="myClubsContainer">
            <div className="clubTitleContainer">
              <div className="clubTitleSectionText">Klubbtilknytning</div>
              <ButtonBase onClick={() => setShowClubModal(!showClubModal)}>
                <div className="showModalLink">Legg til/fjern</div>
              </ButtonBase>
            </div>
            {listClubs()}
          </div>
        ) : (
          <div className="linkToTerms">
            <ButtonBase
              className="showModalLink"
              onClick={() => setShowClubModal(!showClubModal)}
            >
              Tilknytt klubber
            </ButtonBase>
          </div>
        )}
        <div className="myStoresContainer">
          <div className="storesTitleContainer">
            <div className="storesTitleSectionText">Mine butikker</div>
            <ButtonBase onClick={() => setShowFavoriteStoreModal(true)}>
              <div className="showModalLink">Legg til/fjern</div>
            </ButtonBase>
          </div>
          {showFavoriteStore()}
        </div>
        <p className="instructionText">
          Last ned Intersport appen for å få ditt medlemskap på din telefon:{" "}
        </p>
        <div className="badgesContainer">
          <a
            style={{ display: "table-cell" }}
            href="https://play.google.com/store/apps/details?id=no.innit.vinnvinn"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={AndroidIcon}
              className="storeBadgeAndroid"
              alt="Android_store_link"
            />
          </a>
          <a
            style={{ display: "table-cell" }}
            href="https://apps.apple.com/no/app/vinnvinn/id1450257838"
            rel="noreferrer"
            target="_blank"
          >
            <IOSLogo />
          </a>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: getUserSelector(state),
  myClubs: getMyClubsSelector(state),
  allClubs: getAllClubsSelector(state),
  enabledBarcodesFeature: getUseClubBarcodeFlagSelector(state),
  isAuthenticated: getIsAuthenticated(state),
  showFavoriteStoresModal: getShowFavoriteStoresModalSelector(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

Member.propTypes = {
  user: shape({
    intersport_membership_number: string,
  }).isRequired,
  myClubs: arrayOf(shape({})),
  allClubs: arrayOf(shape({})),
  setAllClubs: func.isRequired,
  setMyClubs: func.isRequired,
  enabledBarcodesFeature: bool,
  setUserProfile: func.isRequired,
  isAuthenticated: bool.isRequired,
  showFavoriteStoresModal: bool,
  setShowFavoriteStoreModal: func.isRequired,
};

Member.defualtProps = {
  myClubs: [],
  allClubs: [],
  enabledBarcodesFeature: false,
  showFavoriteStoresModal: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Member);
