import React from "react";
import PropTypes from "prop-types";
import { Navigate, useMatch, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../actions";
import {
  getIsAuthenticated,
  getHasCompleteProfile,
  getUnsubSearchParam,
} from "../utils/selectors";
import Topbar from "../components/TopBar/Topbar";

// isUnsubFlow indicates of login made just for one purpose - unsubscribe from services
function RouteWrapper({
  children,
  isAuthenticated,
  isPrivate,
  hasCompleteProfile,
  setMenu,
  setUnsubUrlSearch,
  isUnsubFlow,
  unsubSearchParam,
}) {
  /**
   * User is redirected to home if trying to open
   * non private routes
   */
  if (isAuthenticated && !isUnsubFlow && !isPrivate) {
    return <Navigate to="/member" replace />;
  }
  /**
   * Redirect user to 'Home' page if he tries to access a private route
   * without authentication.
   */
  if (isPrivate && !isUnsubFlow) {
    if (!isAuthenticated) {
      return <Navigate to="/" replace />;
    }
  }
  if (isUnsubFlow) {
    /**
     * Redirect user to unsubscribe login
     * without authentication.
     */
    const location = useLocation();

    const unsubRouteMatch = useMatch("/unsub");
    const unsubLoginRouteMatch = useMatch("/unsub-login");

    if (isAuthenticated) {
      if (
        (location?.search || unsubSearchParam?.length > 0) &&
        unsubRouteMatch?.pathname === "/unsub"
      ) {
        setUnsubUrlSearch(location.search);
        return children;
      } else {
        return <Navigate to="/" replace />;
      }
    } else {
      // no auth
      // first user goes to login
      // gotta save url param
      if (location?.search && unsubRouteMatch?.pathname === "/unsub") {
        setUnsubUrlSearch(location.search);
        return <Navigate to="/unsub-login" replace />;
      } else if (
        unsubRouteMatch?.pathname === "/unsub" &&
        unsubSearchParam?.length > 0
      ) {
        return children;
      } else if (unsubLoginRouteMatch?.pathname === "/unsub-login") {
        return children;
      } else {
        return <Navigate to="/" replace />;
      }
    }
  }
  if (isPrivate && isAuthenticated && !hasCompleteProfile) {
    /**
     * Redirect user to 'CompleteProfile' page if user is logged in but the userprofile
     * is not complete i.e. not accepted general terms
     */
    const currentRouteMatch = useMatch("/complete_profile");
    setMenu(0);
    if (currentRouteMatch?.pathname === "/complete_profile") {
      return children;
    } else {
      return <Navigate to="/complete_profile" replace />;
    }
  }
  if (isPrivate && isAuthenticated && !isUnsubFlow) {
    // add top bar for auth routes
    return (
      <div>
        <Topbar />
        {children}
      </div>
    );
  }
  if (!isPrivate) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
}

RouteWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  isPrivate: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  hasCompleteProfile: PropTypes.bool.isRequired,
  setMenu: PropTypes.func.isRequired,
  isUnsubFlow: PropTypes.bool,
  setUnsubUrlSearch: PropTypes.func,
  unsubSearchParam: PropTypes.string,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isUnsubFlow: false,
  setUnsubUrlSearch: () => {},
  unsubSearchParam: "",
};

const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),
  hasCompleteProfile: getHasCompleteProfile(state),
  unsubSearchParam: getUnsubSearchParam(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteWrapper);
