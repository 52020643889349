import React from "react";
import { Modal, ButtonBase, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import { logout } from "../../actions/user";
import Requester from "../../api/Requester";
import { bool, func } from "prop-types";
import "./styles.scss";

const DeleteUserModal = ({
  showModal,
  toggleShowDeleteUserModal,
  handleDeleteUser,
  loading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteUserAction = async () => {
    await handleDeleteUser().then(async () => {
      toggleShowDeleteUserModal();
      dispatch(logout());
      await Requester.logout().then(() => {
        navigate("/");
      });
    });
  };

  if (showModal) {
    return (
      <Modal
        aria-labelledby="modal-delete-user"
        aria-describedby="modal-delete-user"
        open={showModal}
        className="deleteModalContainer"
      >
        <div className="deleteUserContainer">
          <div className="outterDeleteUserModalContainer">
            <div className="innerDeleteUserModalContainer">
              <ButtonBase
                onClick={() => toggleShowDeleteUserModal()}
                className="deleteUserExitIconContainer"
                disabled={loading}
              >
                <CancelIcon color="primary" />
              </ButtonBase>
              <div className="deleteIconContainer">
                <WarningIcon className="warningIconStyle" />
                <div className="deleteUserModalTitle">Avslå samtykke</div>
              </div>

              <div className="deleteInfoTextContainer">
                Du er i ferd med å avslå meldemsvilkår. Dette betyr at din
                bruker vil bli slettet etter en uke, og du vil ikke lenger kunne
                bruke Club Intersport og Intersport appen med denne brukeren.
                Brukeren din slettes ikke dersom du godtar meldemsvilkår innen
                denne uken.
              </div>
              <div className="deleteModalButtonsContainer">
                <div className="deleteUserCloseButton">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => toggleShowDeleteUserModal()}
                    className="submitButton"
                    sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                    disabled={loading}
                  >
                    Lukk
                  </Button>
                </div>
                <div className="deleteUserCloseButton">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteUserAction()}
                    className="submitButton"
                    sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                    disabled={loading}
                  >
                    {loading ? "Oppdaterer..." : "Slett meg"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  return null;
};

DeleteUserModal.propTypes = {
  toggleShowDeleteUserModal: func.isRequired,
  showModal: bool.isRequired,
  handleDeleteUser: func.isRequired,
  loading: bool.isRequired,
};

DeleteUserModal.defualtProps = {};

export default DeleteUserModal;
