import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackgroundImage from "../../assets/background.jpg";
import Club from "../../assets/CLUB_logo.png";
import "./styles.scss";

const getHeight = () =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight ||
  "100vh";

function Home() {
  const screenHeight = useSelector((state) => state?.menuReducer?.screenHeight);
  const sectionStyle = {
    height: screenHeight || getHeight(),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundImage: `url(${BackgroundImage})`,
    minHeight: "100%",
  };
  const btnFocusRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    btnFocusRef.current.focus();
  }, []);

  return (
    <section style={sectionStyle}>
      <div className="layoutContainer">
        <div className="logoWrap">
          <div className="logoContainer">
            <img src={Club} className="clubLogo" alt="Club_Intersport" />
          </div>
        </div>
        <div className="buttonsContainer">
          <div />
          <div className="buttonsWrap">
            <Button
              variant="outlined"
              onClick={() => navigate("/login")}
              color="secondary"
              sx={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              Logg inn
            </Button>
            <div className="paddingButton"></div>
            <Button
              ref={btnFocusRef}
              variant="outlined"
              onClick={() => navigate("/register")}
              color="secondary"
              sx={{ paddingTop: "10px", paddingBottom: "10px" }}
              disableRipple
              disableFocusRipple
            >
              Bli Medlem
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
