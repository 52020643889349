import React, { useEffect, useState } from "react";
import { Modal, ButtonBase, CircularProgress, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import { bool, func, arrayOf, shape } from "prop-types";
import Requester from "../../api/Requester";
import "./styles.scss";
import { groupListOnFirstLetter } from "../../utils/methods";
import JoinClubModal from "../JoinClubModal/JoinClubModal";

const ClubPickModal = ({
  showModal,
  toggleShowClubs,
  allClubs,
  myClubs,
  getMyClubsGlobal,
  getAllClubsGlobal,
  loading,
}) => {
  const [showJoinClubModal, setShowJoinClubModal] = useState(false);
  const [clubToJoin, setClubToJoin] = useState({});
  // fiteredClubs are the main source
  // with club id -1 filtered out
  const [filteredClubs, setFilteredClubs] = useState([]);
  const [searchText, setSearchText] = useState("");
  // searchedClubs are used to render list with search string
  const [searchedClubs, setSearchedClubs] = useState([]);

  useEffect(() => {
    if (showModal) {
      getAllClubsGlobal();
      getMyClubsGlobal();
    }
  }, []);

  useEffect(() => {
    if (myClubs && myClubs.length > 0 && allClubs && allClubs.length > 0) {
      const idsOfMyClubs = myClubs.map((club) => club.id);
      const filteredClubs = allClubs.filter((club) => {
        if (idsOfMyClubs.indexOf(club.id) === -1) {
          return club;
        }
        return null;
      });
      setFilteredClubs(filteredClubs);
    } else {
      setFilteredClubs(allClubs);
    }
  }, [myClubs, allClubs]);

  useEffect(() => {
    if (
      filteredClubs?.length > 0 &&
      searchText?.length > 0 &&
      typeof searchText === "string"
    ) {
      const uniFormSearch = searchText.trim().toUpperCase();
      const matchingSearch = filteredClubs.filter((clubItem) => {
        const uniformTitle = clubItem?.name.trim().toUpperCase();
        if (uniformTitle.includes(uniFormSearch)) {
          return true;
        }
        return false;
      });
      debounce(() => setSearchedClubs(matchingSearch), 500);
      setSearchedClubs(matchingSearch);
    }
  }, [filteredClubs, searchText]);

  const handleJoinClubClick = (club) => {
    toggleShowJoinClubModal();
    setClubToJoin(club);
    setSearchText("");
  };

  const listClubsUnder = (arr) => {
    if (arr && arr.length > 0) {
      return arr.map((club, index) => {
        const isItemLast = arr.length - 1 === index; 
        return (
          <div key={club.id} className={isItemLast ? "allClubsItemContainer extraSpaceAfterLetterItems" : "allClubsItemContainer"}>
            <div className="clubTitle">{club.name}</div>
            <ButtonBase onClick={() => handleJoinClubClick(club)}>
              <div className="joinClubText">Tilknytt</div>
            </ButtonBase>
          </div>
        );
      });
    }
    return null;
  };

  const listClubs = () => {
    const collectionToUse =
      searchText?.length > 0 ? searchedClubs : filteredClubs;
    const soretedClubs = groupListOnFirstLetter(collectionToUse);
    if (soretedClubs && soretedClubs.length > 0) {
      return soretedClubs.map((item) => {
        if (!item?.key) {
          return null;
        }

        return (
          <div key={item.key}>
            <div className="allClubsLetter">{item.key}</div>
            {listClubsUnder(item.data)}
          </div>
        );
      });
    }
  };

  const listMyClubs = () => {
    if (myClubs && myClubs.length > 0) {
      return myClubs
        .filter((club) => club.id > 0)
        .map((club) => {
          return (
            <div key={club.id} className="myClubsItem">
              <div className="myClubsItemTitleContainer">
                <div className="myClubsItemTitle">{club.name}</div>
              </div>
              <ButtonBase onClick={() => leaveClub(club.id)}>
                <DeleteIcon className="deleteClubText"></DeleteIcon>
              </ButtonBase>
            </div>
          );
        });
    }
    return null;
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e?.target?.value);
  };

  const renderSearchField = () => {
    return (
      <TextField
        label="Søk"
        size="small"
        className="clubSearchField"
        value={searchText}
        onChange={handleSearchTextChange}
      />
    );
  };

  const leaveClub = async (clubId) => {
    try {
      await Requester.leaveClub(clubId).then(() => {
        getAllClubsGlobal();
        getMyClubsGlobal();
      });
      toast.success("Klubben ble fjernet fra dine klubber!");
    } catch (err) {
      const message = err ?? "Klarte ikke å fjerne klubben...";
      toast.error(message);
    }
  };

  const toggleShowJoinClubModal = () => {
    setShowJoinClubModal(!showJoinClubModal);
  };

  if (showModal) {
    return (
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={showModal}
        onBackdropClick={() => toggleShowClubs()}
      >
        <div className="rulesContainer">
          <JoinClubModal
            showModal={showJoinClubModal}
            toggleShowModal={toggleShowJoinClubModal}
            clubToJoin={clubToJoin}
            getMyClubs={getMyClubsGlobal}
            getAllClubs={getAllClubsGlobal}
            getAllClubsGlobal={getAllClubsGlobal}
            getMyClubsGlobal={getMyClubsGlobal}
          />
          <div className="outterPickModalContainer">
            <div className="innerPickModalContainer">
              {loading ? (
                <div className="loadingContainerInClubpickModal">
                  <CircularProgress size={48} color="primary" />
                </div>
              ) : (
                <div>
                  <div>
                    <ButtonBase
                      onClick={() => toggleShowClubs()}
                      className="exitIconContainer"
                    >
                      <CancelIcon color="primary" />
                    </ButtonBase>
                  </div>
                  {myClubs && myClubs.length > 0 ? (
                    <div>
                      <div className="myClubsTitle ">Mine klubber</div>
                      <div className="myClubsListContainer">
                        {listMyClubs()}
                      </div>
                    </div>
                  ) : null}
                  <div className="clubSearcAndTitleContainer">
                    {filteredClubs && filteredClubs.length > 0 ? (
                      <div className="availableClubs">
                        Tilgengelige klubber:
                      </div>
                    ) : null}
                    <div className="clubSearch">{renderSearchField()}</div>
                  </div>
                  {listClubs()}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return null;
};

ClubPickModal.propTypes = {
  toggleShowClubs: func.isRequired,
  showModal: bool.isRequired,
  getMyClubsGlobal: func.isRequired,
  getAllClubsGlobal: func.isRequired,
  myClubs: arrayOf(shape({})),
  allClubs: arrayOf(shape({})),
  loading: bool.isRequired,
};

ClubPickModal.defualtProps = {};

export default ClubPickModal;
