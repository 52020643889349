import React, { useState, useEffect } from "react";
import { Modal, ButtonBase } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { bool, func } from "prop-types";
import "./styles.scss";
import Requester from "../../api/Requester";

const propTypes = {
  showModal: bool.isRequired,
  toggleShowRules: func.isRequired,
};

const defaultProps = {};

const RulesModal = ({ showModal, toggleShowRules }) => {
  const [termText, setTermText] = useState("");

  const getGeneralTerms = async () => {
    try {
      const terms = await Requester.getAllTerms();
      const generalTerms = terms.find((t) => t.name == "general");

      setTermText(generalTerms.text);
    } catch (err) {
      setTermText(err || "Et problem oppstod under henting av samtykker");
    }
  };

  useEffect(() => {
    getGeneralTerms();
  }, []);

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={showModal}
      onBackdropClick={() => toggleShowRules()}
    >
      <div className="rulesContainer">
        <ButtonBase
          onClick={() => toggleShowRules()}
          className="exitIconContainer"
        >
          <CancelIcon color="primary" />
        </ButtonBase>
        <h2>Medlemsvilkår for Club Intersport</h2>
        <div dangerouslySetInnerHTML={{ __html: termText }} />
      </div>
    </Modal>
  );
};

RulesModal.propTypes = propTypes;
RulesModal.defaultProps = defaultProps;

export default RulesModal;
