import {
  SET_USER,
  LOGOUT_USER,
  SET_USER_TERMS,
  SET_ALL_TERMS,
  SET_SHOW_FAVORITE_STORES_MODAL,
  SET_SHOULD_FOLLOW_STORE,
  SET_SUGGESTED_STORES_TO_FOLLOW,
  SET_USER_FINDING_STORE_SELF,
  SET_IS_FETCHING_SUGGESTED_STORES,
  SET_USER_FAVORITE_STORES,
} from "./types";

export function setUserProfile(data) {
  return {
    type: SET_USER,
    data,
  };
}

export function logout() {
  return {
    type: LOGOUT_USER,
  };
}

export function setAggreedTerms(data) {
  return {
    type: SET_USER_TERMS,
    data,
  };
}

export function setAllTerms(data) {
  return {
    type: SET_ALL_TERMS,
    data,
  };
}

export function setShowFavoriteStoreModal(data) {
  return {
    type: SET_SHOW_FAVORITE_STORES_MODAL,
    data,
  };
}

export function setShouldFollowStore(data) {
  return {
    type: SET_SHOULD_FOLLOW_STORE,
    data,
  };
}

export function setSuggestedStoresToFollow(data) {
  return {
    type: SET_SUGGESTED_STORES_TO_FOLLOW,
    data,
  };
}

export function setUSerFindingStoreSelf(data) {
  return {
    type: SET_USER_FINDING_STORE_SELF,
    data,
  };
}

export function setIsFetchingSuggestedStores(data) {
  return {
    type: SET_IS_FETCHING_SUGGESTED_STORES,
    data,
  };
}

export function setUserFavoriteStores(data) {
  return {
    type: SET_USER_FAVORITE_STORES,
    data,
  };
}
