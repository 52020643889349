import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ButtonBase } from "@mui/material";
import { bool, func, number } from "prop-types";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../actions";
import { useDispatch } from "react-redux";
import { getMenuIndex } from "../../utils/selectors";
import Requester from "../../api/Requester";
import { logout } from "../../actions/user";
import "./styles.scss";

const VerticalMenu = ({ toggleShowModal, menuIndex, showModal, setMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const meniuItems = [
    "Hjem",
    "Samtykker og personvern",
    "Rabattkoder",
    "Logg ut",
  ];

  // Prev menu state
  const prevLocationRef = useRef();
  useEffect(() => {
    prevLocationRef.current = menuIndex;
  });
  const prevMenuIndex = prevLocationRef.current;

  useEffect(() => {
    if (
      showModal === true &&
      prevMenuIndex !== menuIndex &&
      typeof prevMenuIndex !== "undefined"
    ) {
      redirectUser(menuIndex);
      toggleShowModal();
    }
  }, [menuIndex, showModal, prevMenuIndex]);

  const logoutUser = async () => {
    dispatch(logout());
    await Requester.logout().then(() => {
      navigate("/");
    });
  };

  const redirectUser = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        navigate("/member");
        break;
      case 1:
        navigate("/terms");
        break;
      case 2:
        navigate("/coupons");
        break;
      case 3:
        logoutUser();
        setMenu(0);
        break;
      default:
        navigate("/member");
    }
  };

  const handleChange = (newValue) => {
    setMenu(newValue);
  };

  const renderMenuItems = () => {
    return meniuItems.map((item, index) => {
      return (
        <ButtonBase
          key={item}
          onClick={() => handleChange(index)}
          className="verticalItemButton"
        >
          <div
            className={
              index === menuIndex ? "verticalItemSelected" : "verticalItem"
            }
          >
            {item}
          </div>
          <div
            className={
              index === menuIndex
                ? "markedVerticalItem"
                : "unmarkedVerticalItem"
            }
          />
        </ButtonBase>
      );
    });
  };

  return (
    <div className="">
      <div className="verticalMenuContainer">
        <div className="verticalItemsContainer">{renderMenuItems()}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menuIndex: getMenuIndex(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

VerticalMenu.propTypes = {
  toggleShowModal: func.isRequired,
  showModal: bool.isRequired,
  menuIndex: number,
  setMenu: func.isRequired,
};

VerticalMenu.defualtProps = {
  menuIndex: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerticalMenu);
