import React from "react";
import { withStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 30,
    padding: 1,
    paddingBottom: 2,
  },
  switchBase: {
    padding: 6,
    "&$checked": {
      transform: "translateX(21px)",
      color: theme.palette.common.white,
      padding: 6,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {},
  },
  thumb: {
    width: 17,
    height: 17,
  },
  track: {
    borderRadius: 40 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
export default CustomSwitch;
