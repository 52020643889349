import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import userReducer from './reducers/user';
import menuReducer from './reducers/menu'
import clubsReducer from './reducers/clubs';
import featuresReducer from './reducers/features';


const initialState = {};
/* // Initial state
const initialState = {};


const store = createStore(
  reducer,
  initialState,
); */

const rootReducer = combineReducers({
  userReducer,
  menuReducer,
  clubsReducer,
  featuresReducer

});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, initialState);

const persistor = persistStore(store);
export {
  persistor,
  store,
};
