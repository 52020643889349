import React from "react";
import { Modal, ButtonBase, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Barcode from "react-barcode";
import { bool, func, shape } from "prop-types";
import "./styles.scss";

const BarcodeModal = ({ showModal, toggleShowModal, selectedClub }) => {
  const barcodeValue =
    selectedClub && selectedClub.club_membership_number
      ? selectedClub.club_membership_number
      : "";

  const formatMemberNumberForView = (clubNumber) => {
    if (clubNumber) {
      const prefix = clubNumber.substring(0, 4);
      const memberId = clubNumber.substring(4, 11);
      const clubId = clubNumber.substring(11, 16);
      return `${prefix} ${memberId} ${clubId}`;
    }
    return "";
  };

  const showBenefits = () => {
    if (selectedClub && selectedClub.membership_benefits) {
      return (
        <div>
          <p className="discountTextItem">{selectedClub.membership_benefits}</p>
        </div>
      );
    }
    return null;
  };

  const renderDiscountsTitle = () => {
    if (selectedClub && selectedClub.membership_benefits) {
      return <p className="discountTitleText">Medlemsfordeler: </p>;
    } else {
      return null;
    }
  };

  if (showModal) {
    return (
      <Modal
        aria-labelledby="modal-title-barcode"
        aria-describedby="modal-description-barcode"
        open={showModal}
        onBackdropClick={() => toggleShowModal()}
        className="barcodeModalContainer"
      >
        <div className="barcodeContainer">
          <div className="outterBarcodeModalContainer">
            <div className="innerBarcodeModalContainer">
              <ButtonBase
                onClick={() => toggleShowModal()}
                className="barcodeModalexitIconContainer"
              >
                <CancelIcon color="primary" />
              </ButtonBase>
              <div className="barcodeModalTitle">
                {selectedClub && selectedClub.name ? selectedClub.name : ""}
              </div>
              <div className="barcodeSvgContainer">
                <Barcode
                  value={barcodeValue}
                  font="DIN-Pro"
                  displayValue={false}
                  width={3}
                />
                <div className="barcodeTextValue">
                  {formatMemberNumberForView(
                    selectedClub.club_membership_number
                  )}
                </div>
              </div>
              {renderDiscountsTitle()}
              <div className="discountTextContainer">{showBenefits()}</div>
              <div className="barcodeCloseButton">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => toggleShowModal()}
                  className="submitButton"
                  sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  Lukk
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  return null;
};

BarcodeModal.propTypes = {
  toggleShowModal: func.isRequired,
  showModal: bool.isRequired,
  selectedClub: shape({}).isRequired,
};

BarcodeModal.defualtProps = {};

export default BarcodeModal;
