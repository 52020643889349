import React from "react";
import { connect } from "react-redux";
import { ButtonBase } from "@mui/material";
import { getUserSelector } from "../../utils/selectors";
import { shape, func } from "prop-types";
import "./styles.scss";

const UserInfo = ({ user, toggleShowUserForm }) => {
  const hasFirstname = user && user.firstname;
  const hasLastname = user && user.lastname;
  const hasStreet = user && user.streetname;
  const hasPostcode = user && user.postcode;
  const hasCity = user && user.city;
  const hasEmail = user && user.email;
  const isEmptyData =
    !hasFirstname &&
    !hasLastname &&
    !hasStreet &&
    !hasPostcode &&
    !hasCity &&
    !hasEmail;

  const showName = () => {
    if (hasFirstname || hasLastname) {
      return (
        <div className="userNameText">{`${hasFirstname ? user.firstname : ""} ${
          hasLastname ? user.lastname : ""
        }`}</div>
      );
    }
  };

  return (
    <div>
      <div>{showName()}</div>
      {isEmptyData && <div className="noUserinfoText">Ingen data gitt</div>}
      <ButtonBase
        onClick={() => toggleShowUserForm()}
        className="editUserButton"
      >
        <div className="readMoreText">Rediger</div>
      </ButtonBase>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getUserSelector(state),
});

UserInfo.propTypes = {
  user: shape({}).isRequired,
  toggleShowUserForm: func,
};

UserInfo.defualtProps = {
  toggleShowUserForm: () => {},
};

export default connect(mapStateToProps, null)(UserInfo);
