import {
  SET_USER,
  LOGOUT_USER,
  SET_USER_TERMS,
  SET_ALL_TERMS,
  SET_SHOW_FAVORITE_STORES_MODAL,
  SET_SHOULD_FOLLOW_STORE,
  SET_SUGGESTED_STORES_TO_FOLLOW,
  SET_USER_FINDING_STORE_SELF,
  SET_IS_FETCHING_SUGGESTED_STORES,
  SET_USER_FAVORITE_STORES,
} from "../actions/types";

const initialState = {
  me: {},
  isFetching: false,
  isFetchingSuggestedStores: false,
  error: false,
  aggreedTerms: [],
  allTerms: [],
  showFavoriteStoresModal: false,
  shouldFollowStore: false,
  suggestedStoresToFollow: [],
  userFindingStoreSelf: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        error: false,
        me: action.data,
      };

    case LOGOUT_USER:
      return {
        isFetching: false,
        isFetchingSuggestedStores: false,
        error: false,
        me: {},
        profileCheck: [],
        showFavoriteStoreModal: false,
        shouldFollowStore: false,
        suggestedStoresToFollow: [],
        userFindingStoreSelf: false,
      };
    case SET_USER_TERMS:
      return {
        ...state,
        aggreedTerms: action.data,
      };
    case SET_ALL_TERMS:
      return {
        ...state,
        allTerms: action.data,
      };

    case SET_SHOW_FAVORITE_STORES_MODAL:
      return {
        ...state,
        showFavoriteStoresModal: action.data,
      };

    case SET_SHOULD_FOLLOW_STORE:
      return {
        ...state,
        shouldFollowStore: action.data,
      };

    case SET_SUGGESTED_STORES_TO_FOLLOW:
      return {
        ...state,
        suggestedStoresToFollow: action.data,
      };
    case SET_USER_FINDING_STORE_SELF:
      return {
        ...state,
        userFindingStoreSelf: action.data,
      };

    case SET_IS_FETCHING_SUGGESTED_STORES:
      return {
        ...state,
        isFetchingSuggestedStores: action.data,
      };

    case SET_USER_FAVORITE_STORES:
      return {
        ...state,
        me: {
          ...state.me,
          store_follows: action.data,
        },
      };
    default:
      return state;
  }
}
