import React from "react";

import {
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";

import { arrayOf, shape, func } from "prop-types";
import "../../pages/FeedbackForm/styles.scss";

const _renderRadioButtonoptions = (arrOfChoices) => {
  if (arrOfChoices?.length === 0) {
    return null;
  }
  return arrOfChoices.map((answerItem) => {
    return (
      <FormControlLabel
        key={answerItem?.id}
        value={answerItem?.id ? answerItem.id : ""}
        control={<Radio color="primary" />}
        label={answerItem?.option}
      />
    );
  });
};

const _renderSelectOptions = (arrOfChoices) => {
  if (arrOfChoices?.length === 0) {
    return null;
  }

  return arrOfChoices.map((answerItem) => {
    return (
      <MenuItem key={answerItem?.id} value={answerItem?.id}>
        {answerItem?.option}
      </MenuItem>
    );
  });
};

const _renderCheckBoxOptions = (
  arrOfChoices,
  answerArray,
  questionId,
  setAnswerArray
) => {
  if (arrOfChoices?.length === 0) {
    return null;
  }

  const handleCheckBoxToggle = (answerID) => {
    const foundQuestion = answerArray.find(
      (answerItem) => answerItem?.id === questionId
    );
    if (foundQuestion) {
      const foundAnswer = foundQuestion?.options.find(
        (answerId) => answerId === answerID
      );
      if (foundAnswer) {
        // if ID is there from before - remove it by toggle action
        const filteredAnswers = foundQuestion?.options?.filter(
          (answerId) => answerId !== answerID
        );

        const filteredQuestions = answerArray.filter(
          (questionItem) => questionItem.id !== questionId
        );

        const newAnswerArray = filteredQuestions.concat({
          id: questionId,
          options: filteredAnswers,
        });

        setAnswerArray(newAnswerArray);
      } else {
        // hande answer not found
        const filteredQuestions = answerArray.filter(
          (questionItem) => questionItem.id !== questionId
        );

        let questionToUpdate = answerArray.find(
          (questionItem) => questionItem.id === questionId
        );

        if (!questionToUpdate) {
          questionToUpdate = { id: questionId, options: [answerID] };
        } else if (questionToUpdate?.options?.length > 0) {
          questionToUpdate.options = [...questionToUpdate.options, answerID];
        } else if (questionToUpdate?.options?.length === 0) {
          questionToUpdate.options = [answerID];
        }
        const newArrayOfAnswers = filteredQuestions.concat(questionToUpdate);
        setAnswerArray(newArrayOfAnswers);
      }
    } else {
      const newArray = answerArray.concat({
        id: questionId,
        options: [answerID],
      });
      setAnswerArray(newArray);
    }
  };

  return arrOfChoices.map((answerItem) => {
    return (
      <div key={answerItem?.id} className="customQuestionCheckBoxRow">
        <Checkbox
          checked={_findIfCheckBoxMarked(
            answerArray,
            questionId,
            answerItem?.id
          )}
          color="primary"
          onChange={() => handleCheckBoxToggle(answerItem?.id)}
        />
        <div className="customQuestionCheckboxText"> {answerItem?.option}</div>
      </div>
    );
  });
};

const _findOneSelectedValue = (answerArr, questionId, defaultValue = null) => {
  if (answerArr?.length > 0) {
    const answerFound = answerArr.find(
      (answerItem) => answerItem?.id === questionId
    );
    if (answerFound?.options?.length > 0) {
      // answer array always has only 1 answer
      return parseInt(answerFound.options[0]);
    } else {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
};

const _handleSelectOneOption = (
  answerArr,
  setAnserArr,
  questionId,
  answerId
) => {
  const filteredArr = answerArr.filter(
    (questionitem) => questionitem.id !== questionId
  );
  const newArr = [...filteredArr, { id: questionId, options: [answerId] }];
  setAnserArr(newArr);
};

const _findIfCheckBoxMarked = (answerArr, questionId, answerId) => {
  if (
    answerArr?.length === 0 ||
    typeof questionId !== "number" ||
    typeof answerId !== "number"
  ) {
    return false;
  }

  const findQuestion = answerArr.find(
    (questionItem) => questionItem.id === questionId
  );

  if (!findQuestion) {
    return false;
  } else if (
    typeof findQuestion?.id === "number" &&
    findQuestion?.options?.length > 0
  ) {
    const findAnswer = findQuestion?.options.find(
      (answerItem) => answerItem === answerId
    );
    if (findAnswer) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const _handleTextBoxQuestionChange = (
  newValue,
  questionId,
  setAnswerArr,
  answerArr
) => {
  const filteredArr = answerArr.filter(
    (questionItem) => questionItem?.id !== questionId
  );

  const newAnswerArr = filteredArr.concat({
    id: questionId,
    options: [],
    text: newValue,
  });
  setAnswerArr(newAnswerArr);
};

const _findTextBoxValue = (questionId, answerArr) => {
  const foundValue = answerArr.find(
    (quesitonItem) => quesitonItem?.id === questionId
  );

  if (foundValue?.text !== undefined) {
    return foundValue.text;
  } else {
    return "";
  }
};

const RenderCustomQuestionaire = ({
  customQuestionsArr,
  questionnaireAnswers,
  setQuestionnaireAnswers,
}) => {
  if (customQuestionsArr?.length === 0) {
    return null;
  }

  const renderQuestions = () => {
    return customQuestionsArr.map((questionItem) => {
      if (questionItem?.question?.type === "radio") {
        return (
          <div
            className="customQuestionnairItem-wrapper"
            key={questionItem?.id}
            id={questionItem?.id}
          >
            <FormControl component="fieldset" id={questionItem?.id}>
              <div className="customQuestionLabelText">
                {questionItem?.question?.text}*
              </div>
              <RadioGroup
                aria-label={`questionRadioItem-${questionItem?.id}`}
                name={`questionRadioItem-${questionItem?.id}`}
                id={`questionRadioItem-${questionItem?.id}`}
                className="radioFormChoiceContainer-feedback"
                onChange={(e) =>
                  _handleSelectOneOption(
                    questionnaireAnswers,
                    setQuestionnaireAnswers,
                    questionItem?.id,
                    e.target.value
                  )
                }
                value={_findOneSelectedValue(
                  questionnaireAnswers,
                  questionItem?.id,
                  ""
                )}
              >
                <div className="radioFormChoiceContainer-feedback">
                  {_renderRadioButtonoptions(questionItem?.question?.options)}
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        );
      } else if (questionItem?.question?.type === "select") {
        return (
          <div
            className="customQuestionnairItem-wrapper"
            key={questionItem?.id}
            id={questionItem?.id}
          >
            <FormControl
              className="customQuestionnaireSelectContainer"
              component="fieldset"
              id={questionItem?.id}
            >
              <div className="customQuestionLabelText">
                {questionItem?.question?.text}*
              </div>
              <Select
                id={`questionRadioItem-${questionItem?.id}`}
                labelId={`questionRadioItem-${questionItem?.id}`}
                name={`questionRadioItem-${questionItem?.id}`}
                value={_findOneSelectedValue(
                  questionnaireAnswers,
                  questionItem?.id,
                  ""
                )}
                onChange={(e) =>
                  _handleSelectOneOption(
                    questionnaireAnswers,
                    setQuestionnaireAnswers,
                    questionItem?.id,
                    e.target.value
                  )
                }
              >
                {_renderSelectOptions(questionItem?.question?.options)}
              </Select>
            </FormControl>
          </div>
        );
      } else if (questionItem?.question?.type === "text") {
        return (
          <div
            className="feedbackElementWrapper-textfield"
            id={questionItem?.id}
            key={questionItem?.id}
          >
            <div className="customQuestionLabelText-freeText">
              {questionItem?.question?.text}
            </div>
            <TextField
              id={`questionTextItem-${questionItem?.id}`}
              label="Skriv et svar"
              multiline
              color="primary"
              value={_findTextBoxValue(questionItem?.id, questionnaireAnswers)}
              onChange={(e) =>
                _handleTextBoxQuestionChange(
                  e.target.value,
                  questionItem?.id,
                  setQuestionnaireAnswers,
                  questionnaireAnswers
                )
              }
              variant="outlined"
              minRows={5}
            />
          </div>
        );
      } else if (questionItem?.question?.type === "checkbox") {
        return (
          <div
            className="feedbackElementWrapper-textfield"
            id={questionItem?.id}
            key={questionItem?.id}
          >
            <div className="customQuestionLabelText-freeText">
              {questionItem?.question?.text}
            </div>

            {_renderCheckBoxOptions(
              questionItem?.question?.options,
              questionnaireAnswers,
              questionItem?.id,
              setQuestionnaireAnswers
            )}
          </div>
        );
      }

      return null;
    });
  };

  return (
    <div className="feedbackElementWrapper-customQuestionnaire">
      <div className="customQuestionnaireContainer">{renderQuestions()}</div>
    </div>
  );
};

RenderCustomQuestionaire.propTypes = {
  customQuestionsArr: arrayOf(shape({})),
  setQuestionnaireAnswers: func,
  questionnaireAnswers: arrayOf(shape({})),
};

RenderCustomQuestionaire.defualtProps = {
  customQuestionsArr: [],
  questionnaireAnswers: [],
};

export default RenderCustomQuestionaire;
