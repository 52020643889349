import {
  SET_MY_CLUBS,
  SET_ALL_CLUBS
} from '../actions/types';

const initialState = {
  myClubs: [],
  allClubs: []
};

export default function clubs(state = initialState, action) {
  switch (action.type) {
    case SET_MY_CLUBS:
      return {
        ...state,
        myClubs: action.data
      };
    case SET_ALL_CLUBS:
      return {
        ...state,
        allClubs: action.data
      };
    default:
      return state;
  }
}
