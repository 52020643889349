import React from 'react';
import './styles.scss';
import {
  bool
} from 'prop-types';

function Loader({ cover, state }) {

  Loader.propTypes = {
    cover: bool,
    state: bool.isRequired
  };

  Loader.defualtProps = {
    cover: false,
  };

  if (cover && state) {
    return (
      <div className='loader-cover'>
        <div className='loading'>
          <div className='lds-roller'>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    );
  } if (state) {
    return (
      <div className='loading'>
        <div className='lds-roller'>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
  return <></>;
}

Loader.defualtProps = {
  cover: false,
  brightness: 1,
};

export default Loader;
