import {
  SET_MENU,
  SET_UNSUB_SEARCH,
  SET_SCREEN_HEIGHT,
  SET_SCREEN_WIDTH,
} from "./types";

export function setMenu(data) {
  return {
    type: SET_MENU,
    data,
  };
}

export function setUnsubUrlSearch(data) {
  return {
    type: SET_UNSUB_SEARCH,
    data,
  };
}

export function setScreenHeight(data) {
  return {
    type: SET_SCREEN_HEIGHT,
    data,
  };
}

export function setScreenWidth(data) {
  return {
    type: SET_SCREEN_WIDTH,
    data,
  };
}
