/* eslint-disable no-param-reassign */
/* eslint-disable no-throw-literal */
/* eslint-disable no-return-await */
import moment from "moment";
import _ from "lodash";
import Routes from "./Routes";
import Storage from "./Storage";

/**
 * Handle all ajax requests
 */
export default class Requester {
  /* Always use instead of fetch() to format errors and handle flow of network requests properly */
  static async fetch(method, url, auth = false, payload = {}) {
    let response;
    let data;

    payload.method = method;

    const headers = {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (auth) {
      const token = await Storage.get(Storage.KEYS.TOKEN);
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      } else {
        throw "Ikke autentisert til uthenting av data.";
      }
    }

    payload.headers = headers;
    try {
      response = await fetch(url, payload);
      data = await response.json();
    } catch (error) {
      // Catch all request related failures and throw a general no internet alert.
      console.warn("error", error);
      throw "En ukjent feil har oppstått - Vennligst prøv igjen.";
    }

    // Throw response failures given by backend
    if (response.status !== 200 && data.error && data.error instanceof Array) {
      throw data.error.join("\n"); // Throw the array of errors directly as a joined string
    } else if (
      response.status !== 200 &&
      data.errors &&
      _.isPlainObject(data.errors)
    ) {
      // This is used to handle the new validation format from Laravel 5.5 and above.
      const errors = Object.values(data.errors);

      // Doing it this way while we wait for the flat functionality on arrays in JS.
      const flattenedErrors = [].concat(...errors);
      throw flattenedErrors.join("\n"); // Throw the array of errors directly as a joined string
    } else if (response.status !== 200 && data.error) {
      if (data.error === "Unauthenticated.") {
        // Try refreshing the token (might just have expired instead of revoked).
        await this.refreshToken();
        return await this.fetch(method, url, auth, payload);
      } else {
        throw data.error; // Not properly formatted error thrown from backend
      }
    } else if (response.status !== 200) {
      if (data.message) {
        // If the data.message exists, we need to throw this instead of the object itself, to prevent errors.
        // If the message is "Server Error", which is the default message on exceptions from the API. We will
        // just throw empty string, so that each individual catchblock can create their own message.
        throw data.message !== "Server Error" ? data.message : "";
      }
      throw data; // Badly thrown error from backend - Shouldn't happen and need to be backend handled properly.
    } else {
      return data; // Successful request
    }
  }

  static async refreshToken() {
    const token = await Storage.get(Storage.KEYS.REFRESH);
    const meData = await Storage.get(Storage.KEYS.ME);
    const { username } = JSON.parse(meData);

    if (!token) {
      throw "Feilet fornying av login token. Prøv å logge inn på nytt.";
    }

    const response = await fetch(Routes.refresh_token, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        refresh_token: token,
        username,
      }),
    });

    if (response.status !== 200) {
      throw "Feilet fornying av login token. Prøv å logge inn på nytt.";
    } else {
      const data = await response.json();
      const expires_in = moment().add(data.expires_in, "seconds");
      const token_data = [
        [Storage.KEYS.TOKEN, data.access_token],
        [Storage.KEYS.REFRESH, data.refresh_token],
        [Storage.KEYS.TTL, expires_in.format()],
      ];

      return Storage.setMultiple(token_data);
    }
  }

  static getURL(url) {
    return this.fetch("GET", url, false);
  }

  static login(country_code, username) {
    return this.fetch("POST", Routes.login, false, {
      body: JSON.stringify({
        username,
        country_code,
      }),
    });
  }

  static logout() {
    return this.fetch("POST", Routes.logout, true, {
      // No body required. Empty post to prevent XSS logging you out.
    });
  }

  static tokenLogin(payload) {
    return this.fetch("POST", Routes.token_login, false, {
      body: JSON.stringify(payload),
    });
  }

  static register(payload) {
    return this.fetch("POST", Routes.register, false, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: payload.email,
        country_code: payload.country_code,
        phone_number: payload.phone_number,
        postal_code: payload.postal_code,
        birth_year: payload.birth_year,
        gender: payload.gender,
        accepted_general_terms: payload.accepted_general_terms,
        accepted_sms: payload.accepted_sms,
        accepted_email: payload.accepted_email,
        source_campaign: payload.source_campaign,
      }),
    });
  }

  static verify(payload) {
    return this.fetch("POST", Routes.register_confirm, false, {
      body: JSON.stringify(payload),
    });
  }

  static resendVerifySMS(payload) {
    return this.fetch("POST", Routes.resend_verification, false, {
      body: JSON.stringify(payload),
    });
  }

  static getMe() {
    return this.fetch("GET", Routes.me, true);
  }

  static checkToken(appVersion) {
    return this.fetch(
      "GET",
      `${Routes.check_token}?appVersion=${appVersion}`,
      true
    );
  }

  static getAllTerms() {
    return this.fetch("GET", Routes.terms);
  }

  static getApprovedTerms() {
    return this.fetch("GET", Routes.term_approvals, true);
  }

  static updateApprovedTerms(payload) {
    return this.fetch("PUT", Routes.term_approvals, true, {
      body: JSON.stringify(payload),
    });
  }

  static updateMe(payload) {
    return this.fetch("PUT", Routes.me, true, {
      body: JSON.stringify(payload),
    });
  }

  static completeMe(payload) {
    return this.fetch("PUT", Routes.complete_me, true, {
      body: JSON.stringify(payload),
    });
  }

  static getMyClubs() {
    return this.fetch("GET", Routes.myClubs, true);
  }

  static getAllClubs() {
    return this.fetch("GET", `${Routes.clubs}`, true);
  }

  static joinToClub(payload) {
    return this.fetch("POST", Routes.myClubs, true, {
      body: JSON.stringify(payload),
    });
  }

  static leaveClub(clubId) {
    return this.fetch("DELETE", `${Routes.myClubs}/${clubId}`, true);
  }

  static getFeatures() {
    return this.fetch("GET", `${Routes.features}`, true);
  }

  static getMyCoupons() {
    return this.fetch("GET", `${Routes.coupons}`, true);
  }

  static getMyEligibleStores() {
    return this.fetch("GET", `${Routes.eligibleStores}`, true);
  }

  static updateFavoriteStore(storeId) {
    return this.fetch("PUT", `${Routes.updateFavoriteStore}`, true, {
      body: JSON.stringify({
        store_id: storeId || null,
      }),
    });
  }

  static getPublicNewsletter(newsletterId) {
    return this.fetch("GET", `${Routes.publicNewsletter}/${newsletterId}`);
  }

  static checkFeedbackToken(tokenId) {
    return this.fetch("GET", `${Routes.publicFeedback}/${tokenId}`);
  }

  static getStoreName(storeId) {
    return this.fetch("GET", `${Routes.storeName}/${storeId}`);
  }

  static sendFeedbackForm(payload) {
    return this.fetch("POST", `${Routes.sendFeedback}`, false, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stars: payload.stars,
        comment: payload.ratingText,
        feedbackToken: payload.token,
        answers: payload.answers,
      }),
    });
  }

  static getMyFollowStoresSuggestion() {
    return this.fetch("GET", `${Routes.storeFollowSuggestion}`, true);
  }

  static unfollowStore(payload) {
    return this.fetch("DELETE", `${Routes.storeFollow}`, true, {
      body: JSON.stringify(payload),
    });
  }

  static followStores(payload) {
    return this.fetch("POST", `${Routes.storeFollow}`, true, {
      body: JSON.stringify(payload),
    });
  }
}
