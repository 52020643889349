import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { func, string } from "prop-types";
import Requester from "../../api/Requester";
import { ActionCreators } from "../../actions";
import { TextField, Button, CircularProgress } from "@mui/material";
import Storage from "../../api/Storage";
import { getIsAuthenticated, getUnsubSearchParam } from "../../utils/selectors";
import "./styles.scss";
import BackgroundImage from "../../assets/background.jpg";
import Club from "../../assets/CLUB_logo.png";

const windowHeight = window.innerHeight;
const imageHeightDefault = (windowHeight / 3).toString() + "px";
const sectionStyle = {
  height: imageHeightDefault,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundImage: `url(${BackgroundImage})`,
  minHeight: "35%",
};

const UnsubLogin = ({ setUserProfile, setFeatures, unsubSearchParam }) => {
  UnsubLogin.propTypes = {
    setUserProfile: func.isRequired,
    setFeatures: func.isRequired,
    unsubSearchParam: string,
  };

  UnsubLogin.defualtProps = {
    unsubSearchParam: "",
  };

  let location = useLocation();

  // Prev Page and category refs
  const prevLocationRef = useRef();
  useEffect(() => {
    prevLocationRef.current = location;
  });
  const prevLocation = prevLocationRef.current;

  const navigate = useNavigate();

  useEffect(() => {
    if (location.state !== prevLocation) {
      if (
        location.state &&
        location.state.codeReceived &&
        location.state.phone_number
      ) {
        setCodeReceived(true);
        setUsername(location.state.phone_number);
      }
    }
  }, [location]);

  const [username, setUsername] = useState("");
  const [country_code, setCounty_code] = useState("47");
  const [loading, setLoading] = useState(false);
  const [codeReceived, setCodeReceived] = useState(false);
  const [smsCode, setSmsCode] = useState("");
  const [termToUnsub, setTermToUnsub] = useState(null);

  useEffect(() => {
    const unsubTerm = new URLSearchParams(unsubSearchParam).get("term");
    if (unsubTerm && (unsubTerm === "sms" || unsubTerm === "epost")) {
      setTermToUnsub(unsubTerm);
    } else {
      // not allowed to login via unsub
      navigate("/", { replace: true });
    }
  }, [unsubSearchParam]);

  const requestSMS = (event) => {
    event.preventDefault();
    setLoading(true);
    Requester.login(country_code, username)
      .then(() => {
        setCodeReceived(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error === "user_not_found") {
          toast.error("Bruker eksisterer ikke.");
        } else {
          toast.error(error || "Klarte ikke å sende SMS.");
        }
      });
  };

  const verify = () => {
    const payload = {
      country_code: country_code,
      username: username,
      token: smsCode,
      appVersion: "clubintersport",
    };

    setLoading(true);

    Requester.tokenLogin(payload)
      .then((response) => {
        Storage.set(Storage.KEYS.TOKEN, response.token)
          .then(() => {
            Requester.getMe()
              .then((meResponse) => {
                Storage.set(Storage.KEYS.ME, JSON.stringify(meResponse))
                  .then(() => {
                    // store ME in redux
                    navigate("/unsub", { replace: true });
                    setUserProfile(meResponse);
                  })
                  .then(async () => {
                    try {
                      const { features } = await Requester.getFeatures();
                      setFeatures(features);
                    } catch (err) {
                      toast.error(err || "Klarte ikke å logge inn.");
                    }
                  })
                  .catch((error) => {
                    toast.error(error || "Klarte ikke å logge inn.");
                    setLoading(false);
                  });
              })
              .catch((error) => {
                toast.error(error || "Klarte ikke å logge inn.");
                setLoading(false);
              });
          })
          .catch((error) => {
            toast.error(error || "Klarte ikke å logge inn.");
            setLoading(false);
          });
      })
      .catch((error) => {
        toast.error(error || "Klarte ikke å logge inn.");
        setLoading(false);
      });
  };

  const setUser = (e) => {
    setUsername(e.target.value);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    if (codeReceived) {
      verify();
    } else {
      requestSMS(event);
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      handleOnSubmit(event);
    }
  };

  const onSmsCodeChange = (event) => {
    setSmsCode(event.target.value);
  };

  const renderButtonText = () => {
    if (codeReceived === false) {
      return "SEND KODE";
    } else if (!loading && codeReceived === true) {
      return "MELD AV";
    } else {
      return <CircularProgress size={26} className="loader" />;
    }
  };

  return (
    <div className="loginPageWrapper">
      <div>
        <section style={sectionStyle}>
          <div className="layoutContainer">
            <div className="logoWrapUnsub">
              <div className="logoContainer">
                <img src={Club} className="clubLogo" alt="Club_Intersport" />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="loginPageConatinerUnsub">
        <div className="loginContainerUnsub">
          <h1 className="loginHeaderTextUnsub">
            {termToUnsub === "epost" ? "Avmelding nyhetsbrev" : null}
            {termToUnsub === "sms" ? "Avmelding kampanje-SMS" : null}
          </h1>
          {termToUnsub === "epost" ? (
            <div className="unsubInfoContainer">
              <div className="unsubTextLine">
                Du er nå i ferd med å melde deg av nyhetsbrevet fra Club
                Intersport.
              </div>
              <div className="unsubTextLine">
                For å gjennomføre avmeldingen må du logge inn.
              </div>
            </div>
          ) : null}
          {termToUnsub === "sms" ? (
            <div className="unsubInfoContainer">
              <div className="unsubTextLine">
                Du vil ikke lenger motta kampanje-SMS fra Club Intersport.
              </div>
              <div className="unsubTextLine">
                For å gjennomføre avmeldingen må du logge inn.
              </div>
            </div>
          ) : null}

          <form className="smsCodeInput" onSubmit={(e) => handleOnSubmit(e)}>
            <div className="loginPhoneContainer">
              <TextField
                value={country_code}
                className="landCodeInputLogin"
                variant="outlined"
                label="Landkode"
                type="number"
                disabled
                sx={{ minWidth: 85 }}
                onChange={(e) => {
                  if (e?.target?.value || e?.target?.value === "") {
                    setCounty_code(e.target.value);
                  }
                }}
              />

              <TextField
                value={username}
                type="number"
                autoFocus
                className="loginInput"
                variant="outlined"
                label="Telefonnummer"
                onChange={(e) => {
                  setUser(e);
                }}
                onKeyPress={(e) => handleEnterPress(e)}
              />
            </div>
          </form>

          {codeReceived && (
            <form className="smsCodeInput" onSubmit={(e) => handleOnSubmit(e)}>
              <TextField
                autoFocus
                className="loginInput"
                variant="outlined"
                label="SMS-Kode"
                onChange={(e) => onSmsCodeChange(e)}
              />
            </form>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleOnSubmit(e)}
            className="submitButton"
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            {renderButtonText()}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/")}
            className="submitButton"
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            Avbryt
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),
  unsubSearchParam: getUnsubSearchParam(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UnsubLogin);
