import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  TextField,
  Button,
  Checkbox,
  ButtonBase,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import Requester from "../../api/Requester";
import Loader from "../../components/Loader/Loader";
import RulesModal from "../../components/RulesModal/RulesModal";
import { isEmail } from "../../utils/methods";
import "./styles.scss";
import { getParams } from "../../utils/methods.js";
import { SET_USER, SET_USER_TERMS } from "../../actions/types";
import { logout } from "../../actions/user";

const CompleteUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [phoneCode, setPhoneCode] = useState("47");
  const [phone, setPhone] = useState(getParams("tlf", "", true));
  const [postNr, setPostNr] = useState(getParams("zip", "", true));
  const [birthY, setBirthY] = useState("");
  const [gender, setGender] = useState("");
  const [rulesAccept, setRulesAccept] = useState(false);
  const [emailAccept, setEmailAccept] = useState(false);
  const [smsAccept, setSmsAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [allTerms, setAllTerms] = useState([]);

  const userProfile = useSelector((state) => state?.userReducer?.me);

  // mobile render
  const [isMobileSize, setIsMobileSize] = useState(false);
  const screenWidth = useSelector((state) => state?.menuReducer?.screenWidth);

  useEffect(() => {
    if (screenWidth <= 500) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    // preset values that exists
    if (userProfile?.email) {
      setEmail(userProfile.email);
    }
    if (userProfile?.username) {
      setPhone(userProfile.username);
    }
    if (userProfile?.postcode) {
      setPostNr(userProfile.postcode);
    }
    if (userProfile?.birth_year) {
      setBirthY(userProfile.birth_year.toString());
    }
    if (userProfile.gender) {
      setGender(userProfile.gender);
    }
    if (
      userProfile?.email ||
      userProfile?.username ||
      userProfile?.postcode ||
      userProfile?.birth_year ||
      userProfile.gender
    ) {
      setEditMode(true);
    }
  }, [userProfile]);

  useEffect(() => {
    if (editMode === true) {
      validateInput();
    }
  }, [phone, postNr, birthY, rulesAccept, email, gender]);

  useEffect(() => {
    getAllTerms();
  }, []);

  const validateEmail = (mail) => {
    const checkIfEmail = isEmail(mail);
    if (checkIfEmail === true) {
      const updatedError = errors;
      updatedError.emailError = null;
      setErrors({ ...errors, updatedError });
    } else {
      const updatedError = errors;
      updatedError.emailError = "E-post adresse er påkrevd.";
      setErrors({ ...errors, updatedError });
    }
  };

  const validateInput = () => {
    setEditMode(true);
    validateEmail(email);
    if (phone.length !== 8) {
      const updatedPhoneError = errors;
      updatedPhoneError.phoneError = "Mobilnummer må inneholde 8 sifre.";
      setErrors({ ...errors, updatedPhoneError });
    }
    if (phone.length === 8) {
      const updatedPhoneError = errors;
      updatedPhoneError.phoneError = null;
      setErrors({ ...errors, updatedPhoneError });
    }

    if (postNr.length === 4) {
      const updatedPostNrError = errors;
      updatedPostNrError.postNrError = null;
      setErrors({ ...errors, updatedPostNrError });
    }

    if (postNr.length !== 4) {
      const updatedPostNrError = errors;
      updatedPostNrError.postNrError = "Postnummer må inneholde 4 sifre.";
      setErrors({ ...errors, updatedPostNrError });
    }

    if (birthY?.length === 4 || !birthY) {
      const updatedBirthYError = errors;
      updatedBirthYError.birtYError = null;
      setErrors({ ...errors, updatedBirthYError });
    }

    if (birthY?.length !== 4 && birthY) {
      const updatedBirthYError = errors;
      updatedBirthYError.birtYError = "Fødselsår må inneholde 4 sifre.";
      setErrors({ ...errors, updatedBirthYError });
    }
    if (!gender) {
      const updatedGenderError = errors;
      updatedGenderError.GenderError = "Kjønn er påkrevd";
      setErrors({ ...errors, updatedGenderError });
    }

    if (gender) {
      const updatedGenderError = errors;
      updatedGenderError.GenderError = null;
      setErrors({ ...errors, updatedGenderError });
    }

    if (rulesAccept === false) {
      const updatedRulesAcceptError = errors;
      updatedRulesAcceptError.rulesAcceptError =
        "Du må godta vilkår for å melde deg inn.";
      setErrors({ ...errors, updatedRulesAcceptError });
    }

    if (rulesAccept === true) {
      const updatedRulesAcceptError = errors;
      updatedRulesAcceptError.rulesAcceptError = null;
      setErrors({ ...errors, updatedRulesAcceptError });
    }
  };

  const findApprovedTermIds = () => {
    if (allTerms?.length > 0) {
      const filteredTerms = allTerms.filter((term) => {
        if (
          (term.name === "general" && rulesAccept) ||
          (term.name === "sms" && smsAccept) ||
          (term.name === "epost" && emailAccept)
        ) {
          return term;
        }
      });
      return filteredTerms.map((term) => term.id);
    }
    return [];
  };

  const sendCompletionRequest = async () => {
    validateInput();
    // If no errors - send API req
    if (
      !errors.emailError &&
      !errors.phoneError &&
      !errors.postNrError &&
      !errors.birtYError &&
      !errors.rulesAcceptError
    ) {
      setLoading(true);

      const payload = {
        email,
        postcode: postNr,
        birthyear: birthY,
        gender,
        approved_term_ids: findApprovedTermIds(),
      };

      try {
        const response = await Requester.completeMe(payload);
        if (response && response?.terms) {
          const filterTermIds = response.terms.map((term) => term.id);

          dispatch({ type: SET_USER, data: response });
          dispatch({ type: SET_USER_TERMS, data: filterTermIds });
          navigate("/member", { replace: true });
        }
      } catch (err) {
        toast.error(err || "Oppdatering av profil feilet");
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleShowRules = () => {
    setShowRules(!showRules);
  };

  const getAllTerms = async () => {
    setLoading(true);
    try {
      await Requester.getAllTerms().then((res) => {
        setAllTerms(res);
      });
    } catch (err) {
      toast.error(err || "Klarte ikke å hente samtykker...");
    } finally {
      setLoading(false);
    }
  };

  const logOut = () => {
    dispatch(logout());
  };

  return (
    <div className="completeProfileContainer">
      <Loader cover state={loading} />
      <p className="completionTitle">Fullfør medlemsprofil</p>
      <RulesModal showModal={showRules} toggleShowRules={toggleShowRules} />
      <form className="formContainer">
        <div className="phoneAndEmailContainer">
          <TextField
            autoFocus
            value={email}
            className="inputFieldStyle"
            variant="outlined"
            label="E-post*"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            error={errors.emailError ? true : null}
            helperText={errors.emailError ? errors.emailError : null}
          />
          <div className="phonesContainer">
            <div className="phoneCodeContainer">
              <TextField
                value={phoneCode}
                type="number"
                className="landCodeInput"
                variant="outlined"
                disabled
                label="Landkode*"
                onChange={(e) => {
                  setPhoneCode(e.target.value);
                }}
              />
            </div>
            <TextField
              value={phone}
              type="number"
              variant="outlined"
              label="Mobilnummer*"
              disabled
              className="phoneNumberInput"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              error={errors.phoneError ? true : null}
              helperText={errors.phoneError ? errors.phoneError : null}
            />
          </div>
        </div>
        <TextField
          value={postNr}
          type="number"
          className="inputFieldStyle"
          variant="outlined"
          label="Postnummer*"
          onChange={(e) => {
            setPostNr(e.target.value);
          }}
          error={errors.postNrError ? true : null}
          helperText={errors.postNrError ? errors.postNrError : null}
        />
        <TextField
          type="number"
          className="inputFieldStyle"
          variant="outlined"
          label="Fødselsår"
          value={birthY}
          onChange={(e) => {
            setBirthY(e.target.value);
          }}
          error={errors.birtYError ? true : null}
          helperText={errors.birtYError ? errors.birtYError : null}
        />
        <FormControl
          component="fieldset"
          error={errors.GenderError ? true : null}
        >
          <FormLabel component="legend">Kjønn*</FormLabel>
          <RadioGroup
            row
            aria-label="kjønn"
            name="row-radio-buttons-group"
            onChange={(e) => setGender(e.target.value)}
            value={gender}
          >
            <FormControlLabel
              value="mann"
              control={
                <Radio
                  color="primary"
                  size={isMobileSize ? "medium" : "small"}
                />
              }
              label="Mann"
            />
            <FormControlLabel
              value="kvinne"
              control={
                <Radio
                  color="primary"
                  size={isMobileSize ? "medium" : "small"}
                />
              }
              label="Kvinne"
            />
          </RadioGroup>
          <FormHelperText>
            {errors.GenderError ? errors.GenderError : null}
          </FormHelperText>
        </FormControl>
        <div className="allCheckBoxContainer">
          <div className="checkBoxContainer">
            <Checkbox
              checked={emailAccept === true ? true : false}
              color="primary"
              onChange={() => setEmailAccept(!emailAccept)}
              className="test"
              size={isMobileSize ? "medium" : "small"}
            />
            <div className="conditionText">
              Jeg samtykker til å motta e-post
            </div>
          </div>
          <div className="checkBoxContainer">
            <Checkbox
              checked={smsAccept === true ? true : false}
              color="primary"
              onChange={() => setSmsAccept(!smsAccept)}
              size={isMobileSize ? "medium" : "small"}
            />
            <div className="conditionText">Jeg samtykker til å motta sms</div>
          </div>
          <div className="checkBoxContainer">
            <Checkbox
              checked={rulesAccept === true ? true : false}
              required
              color="primary"
              onChange={() => setRulesAccept(!rulesAccept)}
              size={isMobileSize ? "medium" : "small"}
            />
            <div className="conditionText">
              Jeg samtykker til
              <ButtonBase
                className="showModalLink"
                onClick={() => toggleShowRules()}
              >
                medlemsvilkår*
              </ButtonBase>
            </div>
          </div>
        </div>
        <div className="conditionsError">
          {errors.rulesAcceptError ? errors.rulesAcceptError : null}
        </div>
      </form>
      <div className="completionContainer">
        <Button
          onClick={async () => await sendCompletionRequest()}
          color="primary"
          variant="contained"
          className="completeButton"
          sx={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          Fullfør profil
        </Button>
      </div>
      <div className="astreixInfo">* - påkrevde felter</div>
      <div className="containerLinkToLogout">
        <div>Vil du logge ut? Klikk </div>
        <ButtonBase onClick={() => logOut()} className="linkToSignIn">
          her.
        </ButtonBase>
      </div>
    </div>
  );
};

export default CompleteUser;
