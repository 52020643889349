import {
  SET_FEATURES
} from '../actions/types';

const initialState = {
  features: [],
};

export default function features(state = initialState, action) {
  switch (action.type) {
    case SET_FEATURES:
      return {
        ...state,
        features: action.data
      };
    default:
      return state;
  }
}
