import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  TextField,
  Button,
  Checkbox,
  ButtonBase,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import Requester from "../../api/Requester";
import Loader from "../../components/Loader/Loader";
import RulesModal from "../../components/RulesModal/RulesModal";
import { isEmail } from "../../utils/methods";
import "./styles.scss";
import { getParams } from "../../utils/methods.js";
import BackgroundImage from "../../assets/background.jpg";
import Club from "../../assets/CLUB_logo.png";
import "./styles.scss";

const windowHeight = window.innerHeight;
const imageHeightDefault = (windowHeight / 3).toString() + "px";

const sectionStyle = {
  height: imageHeightDefault,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundImage: `url(${BackgroundImage})`,
  minHeight: "30%",
};

const SignUp = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [email, setEmail] = useState("");
  const [phoneCode, setPhoneCode] = useState("47");
  const [phone, setPhone] = useState(getParams("tlf", "", true));
  const [postNr, setPostNr] = useState(getParams("zip", "", true));
  const [birthY, setBirthY] = useState("");
  const [gender, setGender] = useState("");
  const [rulesAccept, setRulesAccept] = useState(false);
  const [emailAccept, setEmailAccept] = useState(false);
  const [smsAccept, setSmsAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [showRules, setShowRules] = useState(false);

  // mobile render
  const [isMobileSize, setIsMobileSize] = useState(false);
  const screenWidth = useSelector((state) => state?.menuReducer?.screenWidth);

  useEffect(() => {
    if (screenWidth <= 500) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    if (editMode === true) {
      validateInput();
    }
  }, [phone, postNr, birthY, rulesAccept, email, gender]);

  const validateEmail = (mail) => {
    const checkIfEmail = isEmail(mail);
    if (checkIfEmail === true) {
      const updatedError = errors;
      updatedError.emailError = null;
      setErrors({ ...errors, updatedError });
    } else {
      const updatedError = errors;
      updatedError.emailError = "E-post adresse er påkrevd.";
      setErrors({ ...errors, updatedError });
    }
  };

  const validateInput = () => {
    setEditMode(true);
    validateEmail(email);
    if (phone.length !== 8) {
      const updatedPhoneError = errors;
      updatedPhoneError.phoneError = "Mobilnummer må inneholde 8 sifre.";
      setErrors({ ...errors, updatedPhoneError });
    }
    if (phone.length === 8) {
      const updatedPhoneError = errors;
      updatedPhoneError.phoneError = null;
      setErrors({ ...errors, updatedPhoneError });
    }

    if (postNr.length === 4) {
      const updatedPostNrError = errors;
      updatedPostNrError.postNrError = null;
      setErrors({ ...errors, updatedPostNrError });
    }

    if (postNr.length !== 4) {
      const updatedPostNrError = errors;
      updatedPostNrError.postNrError = "Postnummer må inneholde 4 sifre.";
      setErrors({ ...errors, updatedPostNrError });
    }

    if (birthY.length === 4 || !birthY) {
      const updatedBirthYError = errors;
      updatedBirthYError.birtYError = null;
      setErrors({ ...errors, updatedBirthYError });
    }

    if (birthY.length !== 4 && birthY) {
      const updatedBirthYError = errors;
      updatedBirthYError.birtYError = "Fødselsår må inneholde 4 sifre.";
      setErrors({ ...errors, updatedBirthYError });
    }
    if (!gender) {
      const updatedGenderError = errors;
      updatedGenderError.GenderError = "Kjønn er påkrevd";
      setErrors({ ...errors, updatedGenderError });
    }

    if (gender) {
      const updatedGenderError = errors;
      updatedGenderError.GenderError = null;
      setErrors({ ...errors, updatedGenderError });
    }

    if (rulesAccept === false) {
      const updatedRulesAcceptError = errors;
      updatedRulesAcceptError.rulesAcceptError =
        "Du må godta vilkår for å melde deg inn.";
      setErrors({ ...errors, updatedRulesAcceptError });
    }

    if (rulesAccept === true) {
      const updatedRulesAcceptError = errors;
      updatedRulesAcceptError.rulesAcceptError = null;
      setErrors({ ...errors, updatedRulesAcceptError });
    }
  };

  const sendRegisterRequest = async () => {
    validateInput();

    // If no errors - send API req
    if (
      !errors.emailError &&
      !errors.phoneError &&
      !errors.postNrError &&
      !errors.birtYError &&
      !errors.rulesAcceptError
    ) {
      setLoading(true);
      const isSourceCampaignLink =
        typeof search === "string" && search === "?utm_campaign" ? true : false;

      const mappedPayload = {
        email: email,
        country_code: phoneCode,
        phone_number: phone,
        postal_code: postNr,
        birth_year: birthY,
        gender: gender,
        accepted_general_terms: rulesAccept,
        accepted_sms: smsAccept,
        accepted_email: emailAccept,
        source_campaign: isSourceCampaignLink,
      };

      try {
        const response = await Requester.register(mappedPayload);
        if (response === "OK") {
          navigate("/login", {
            state: { codeReceived: true, phone_number: phone },
            replace: true,
          });
        }

        setLoading(false);
      } catch (err) {
        toast.error(err || "Registrering feilet");
        setLoading(false);
      }
    }
  };

  const sendUserToLogin = () => {
    navigate("/login", { replace: true });
  };

  const toggleShowRules = () => {
    setShowRules(!showRules);
  };

  return (
    <div className="loginPageWrapper">
      <section style={sectionStyle}>
        <div className="layoutContainer">
          <div className="logoWrapUnsub">
            <div className="logoContainer">
              <ButtonBase onClick={() => navigate("/")}>
                <img src={Club} className="clubLogo" alt="Club_Intersport" />
              </ButtonBase>
            </div>
          </div>
        </div>
      </section>
      <div className="signUpContainer">
        <Loader cover state={loading} />
        <p className="registerTitle">Registreringsskjema</p>
        <RulesModal showModal={showRules} toggleShowRules={toggleShowRules} />
        <form className="formContainer">
          <div className="phoneAndEmailContainer">
            <TextField
              autoFocus
              value={email}
              className="inputFieldStyle"
              variant="outlined"
              label="E-post*"
              onChange={(e) => {
                const trimmedEmail = e.target.value.trim();
                setEmail(trimmedEmail);
              }}
              error={errors.emailError ? true : null}
              helperText={errors.emailError ? errors.emailError : null}
            />
            <div className="phonesContainer">
              <div className="phoneCodeContainer">
                <TextField
                  value={phoneCode}
                  type="number"
                  className="landCodeInput"
                  variant="outlined"
                  disabled
                  label="Landkode*"
                  onChange={(e) => {
                    const trimmedValue = e.target.value.trim();
                    setPhoneCode(trimmedValue);
                  }}
                />
              </div>
              <TextField
                value={phone}
                type="number"
                variant="outlined"
                label="Mobilnummer*"
                className="phoneNumberInput"
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim();
                  setPhone(trimmedValue);
                }}
                error={errors.phoneError ? true : null}
                helperText={errors.phoneError ? errors.phoneError : null}
              />
            </div>
          </div>
          <TextField
            value={postNr}
            type="number"
            className="inputFieldStyle"
            variant="outlined"
            label="Postnummer*"
            onChange={(e) => {
              const trimmedValue = e.target.value.trim();
              setPostNr(trimmedValue);
            }}
            error={errors.postNrError ? true : null}
            helperText={errors.postNrError ? errors.postNrError : null}
          />
          <TextField
            type="number"
            className="inputFieldStyle"
            variant="outlined"
            label="Fødselsår*"
            onChange={(e) => {
              const trimmedValue = e.target.value.trim();
              setBirthY(trimmedValue);
            }}
            error={errors.birtYError ? true : null}
            helperText={errors.birtYError ? errors.birtYError : null}
          />
          <FormControl
            component="fieldset"
            error={errors.GenderError ? true : null}
          >
            <FormLabel component="legend">Kjønn*</FormLabel>
            <RadioGroup
              row
              aria-label="kjønn"
              name="row-radio-buttons-group"
              onChange={(e) => setGender(e.target.value)}
            >
              <FormControlLabel
                value="mann"
                control={
                  <Radio
                    color="primary"
                    size={isMobileSize ? "medium" : "small"}
                  />
                }
                label="Mann"
              />
              <FormControlLabel
                value="kvinne"
                control={
                  <Radio
                    color="primary"
                    size={isMobileSize ? "medium" : "small"}
                  />
                }
                label="Kvinne"
              />
            </RadioGroup>
            <FormHelperText>
              {errors.GenderError ? errors.GenderError : null}
            </FormHelperText>
          </FormControl>
          <div className="allCheckBoxContainer">
            <div className="checkBoxContainer">
              <Checkbox
                checked={emailAccept === true ? true : false}
                color="primary"
                onChange={() => setEmailAccept(!emailAccept)}
                className="test"
                size={isMobileSize ? "medium" : "small"}
              />
              <div className="conditionText">
                Jeg samtykker til å motta e-post
              </div>
            </div>
            <div className="checkBoxContainer">
              <Checkbox
                checked={smsAccept === true ? true : false}
                color="primary"
                onChange={() => setSmsAccept(!smsAccept)}
                size={isMobileSize ? "medium" : "small"}
              />
              <div className="conditionText">Jeg samtykker til å motta sms</div>
            </div>
            <div className="checkBoxContainer">
              <Checkbox
                checked={rulesAccept === true ? true : false}
                required
                color="primary"
                onChange={() => setRulesAccept(!rulesAccept)}
                size={isMobileSize ? "medium" : "small"}
              />
              <div className="conditionText">
                Jeg samtykker til
                <ButtonBase
                  className="showModalLink"
                  onClick={() => toggleShowRules()}
                >
                  medlemsvilkår*
                </ButtonBase>
              </div>
            </div>
          </div>
          <div className="conditionsError">
            {errors.rulesAcceptError ? errors.rulesAcceptError : null}
          </div>
        </form>
        <div className="registerContainer">
          <Button
            onClick={async () => await sendRegisterRequest()}
            color="primary"
            variant="contained"
            className="registerButton"
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            Bli medlem
          </Button>
        </div>
        <div className="astreixInfo">* - påkrevde felter</div>
        <div className="containerLinkToSignIn">
          <div>Allerede en medlem? Logg inn </div>
          <ButtonBase
            onClick={() => sendUserToLogin()}
            className="linkToSignIn"
          >
            her.
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
