import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../actions";
import { toast } from "react-toastify";
import { TextField, Button } from "@mui/material";
import { getUserSelector } from "../../utils/selectors";
import { shape, func } from "prop-types";
import api from "../../api/Requester";
import "./styles.scss";

const UserForm = ({ user, setLoading, toggleShowUserForm, setUserProfile }) => {
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [streetname, setStreetname] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    // on first mount
    if (user) {
      if (user.firstname) {
        setFirstname(user.firstname);
      }
      if (user.lastname) {
        setLastname(user.lastname);
      }
      if (user.streetname) {
        setStreetname(user.streetname);
      }
      if (user.postcode) {
        setPostcode(user.postcode);
      }
      if (user.city) {
        setCity(user.city);
      }
      if (user.email) {
        setEmail(user.email);
      }
    }
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      firstname: firstName,
      lastname: lastName,
      streetname,
      postcode,
      email,
      city,
    };

    try {
      const res = await api.updateMe(payload);
      if (res) {
        setUserProfile(res);
      }
      toggleShowUserForm();
      toast.success("Opplysningene ble oppdatert!");
    } catch (err) {
      console.log("ERR", err);
      toast.error(err || "Klarte ikke å oppdatere opplysningene...");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="userFormContainer">
      <TextField
        label="Fornavn*"
        className="formItem"
        variant="outlined"
        type="text"
        value={firstName}
        placeholder="Fornavn"
        onChange={(e) => {
          setFirstname(e.target.value);
        }}
      />
      <TextField
        className="formItem"
        variant="outlined"
        type="text"
        value={lastName}
        label="Etternavn*"
        placeholder="Etternavn"
        onChange={(e) => {
          setLastname(e.target.value);
        }}
      />
      <TextField
        className="formItem"
        variant="outlined"
        type="text"
        value={streetname}
        placeholder="Addresse"
        label="Addresse"
        onChange={(e) => {
          setStreetname(e.target.value);
        }}
      />
      <TextField
        className="formItem"
        variant="outlined"
        type="number"
        value={postcode}
        placeholder="Postkode"
        label="Postkode"
        onChange={(e) => {
          setPostcode(e.target.value);
        }}
      />
      <TextField
        className="formItem"
        variant="outlined"
        type="text"
        value={city}
        placeholder="By"
        label="By"
        onChange={(e) => {
          setCity(e.target.value);
        }}
      />
      <TextField
        className="formItem"
        variant="outlined"
        type="text"
        value={email}
        placeholder="Epost"
        label="Epost"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <div className="userFormActionButtonContainer">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          sx={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          Oppdater
        </Button>
        <div className="closeButtonStyle">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => toggleShowUserForm()}
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            Skjul
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getUserSelector(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

UserForm.propTypes = {
  user: shape({}).isRequired,
  setLoading: func,
  toggleShowUserForm: func,
  setUserProfile: func,
};

UserForm.defualtProps = {
  setLoading: () => {},
  toggleShowUserForm: () => {},
  setUserProfile: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
