import React from "react";
import { Modal, ButtonBase } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import { bool, func } from "prop-types";
import VerticalMenu from "./VerticalMenu";
import "./styles.scss";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
}));

const MobileMenuModal = ({ showModal, toggleShowModal }) => {
  const classes = useStyles();
  if (showModal) {
    return (
      <Modal
        aria-labelledby="modal-title-menu"
        aria-describedby="modal-description-menu"
        open={showModal}
        className={classes.modal}
      >
        <div className="mobileMenuModal">
          <div className="mobileMenuModalWrap">
            <ButtonBase
              onClick={() => toggleShowModal()}
              className="closeMobileMenuIconContainer"
            >
              <ClearIcon className="clearIcon" />
              <div className="mobileMenuTextOpen">MENY</div>
            </ButtonBase>
            <div className="mobileMenuItemsContainer">
              <VerticalMenu
                toggleShowModal={toggleShowModal}
                showModal={showModal}
              />
            </div>
          </div>
          <div className="mobileMenuModalWrapBottom"></div>
        </div>
      </Modal>
    );
  }
  return null;
};

MobileMenuModal.propTypes = {
  toggleShowModal: func.isRequired,
  showModal: bool.isRequired,
};

MobileMenuModal.defualtProps = {};

export default MobileMenuModal;
