import React from 'react';
import Club from "../../assets/CLUB_logo.png";
import './styles.scss';


const Policy = () => {

  return (

    <div className="layoutContainerPolicy">
      <div className="logoWrapPolicy">
        <div className="logoContainer">
          <img src={Club} className="clubLogo" alt="Club_Intersport" />
        </div>
      </div>
      <div className="policyContainer">
        <div className="policyTextContainer">
          <h1>Personvernerklæring (privacy policy) for appen Club Intersport</h1>

          <p>Innit AS har utviklet appen som en gratis app. Tjenesten og appen forvaltes av Innit AS på vegne av Intersport. Club Intersport er et fordelsprogram for Intersport og drives av INO Drift AS (org. nr. 923 929 533 ) gjennom en franchisekjede.</p>
          <p>Denne siden brukes til å informere besøkende om hvilke data som samles og hva de brukes til.</p>
          <p>Dersom du bruker tjenesten godtar du innsamling av data og bruken av data som beskrevet i denne erklæringen.</p>
          <p>Vi vil ikke bruke eller dele innsamlet informasjon med noen andre enn beskrevet på denne siden.</p>

          <h2>Innsamling og bruk av data</h2>
          <p>All brukerinformasjon som er tilgjengelig i appen eies av Intersport Norge.</p>

          <p>Appen tilbyr brukerregistrering og innlogging som medlem i Club Intersport. I appen kan medlemmer handle varer, se sine medlemsfordeler og tilbud, kjøpe klubbprodukter og administrere sitt medlemskap. </p>

          <p>Tjensten Club Intersport samler følgende brukerinformasjon:</p>
          <ul>
            <li>Telefonnummer (identifikator)</li>
            <li>Medlemsnummer</li>
            <li>Samtykker til SMS, e-post, push, generelle medlemsvilkår, Pinch og Matomo analytics</li>
            <li>Adresse og postnr (valgfritt)</li>
            <li>Kjønn (valgfritt)</li>
            <li>Fødselsår (valgfritt)</li>
            <li>Kjøp av varer i e-handel</li>
            <li>Favorittbutikk (valgfritt)</li>
            <li>Knytning mot idrettslag (valgfritt)</li>
          </ul>

          <p>Appen benytter seg av forskjellige tredjepartsleverandører. Disse er:</p>
          <ul>
            <li>Google Play Service (https://www.google.com/policies/privacy/)</li>
            <li>Matomo (https://matomo.org/privacy-policy/)</li>
            <li>One Signal (https://onesignal.com/privacy_policy)</li>
            <li>EG Norge (https://eg.no/om-eg/personopplysningspolicy/)</li>
            <li>Fluxloop (https://www.fluxloop.com/privacy-policy-intro)</li>
            <li>Swedbank Pay (https://www.swedbankpay.no/personvern)</li>
            <li>Vipps (https://vipps.no/vilkar/vipps-privacy-protection-policy/)</li>
          </ul>

          <h3>Lokasjonstjenester for bruk av Pinch i butikk (Fluxloop)</h3>
          <p>Å aktivere lokasjon er valgfritt for sluttbruker. Tjenesten Pinch brukes til å sjekke inn på en fysisk butikk. Pinch og oppsett av beacons i butikk leveres av 3. part Fluxloop. <a href="https://www.fluxloop.com/privacy-policy-intro">(Se Fluxloop sin privacy policy)</a> .</p>
          <p>Lokasjon fungerer på følgende vis:</p>
          <ol>
            <li>Pinch må aktivers fra siden over samtykker på Min profil / Samtykker i app</li>
            <li>Ved aktivering av Pinch spør telefonen om tillatelse til å bruke lokasjon</li>
            <li>Dersom bruker tillater bruk av location vil appen være i stand til å kommunisere med Bluetooth beacons i fysisk butikk</li>
            <li>Data som utveksles med Fluxloop er sluttbrukers aldersgruppe, postnr, kjønn, devic id og hvilken butikk/beacon som besøkes. <strong>Merk:</strong> informasjonen er anonym og ikke knyttet mot person.</li>
            <li>Etter noe tid fra besøket i fysisk butikk er registrert, vil systemet sende push-melding til sluttbruker med ønske om å gi tilbakemelding på service</li>
            <li>Da bruker klikker på denne push-meldingen vil appen åpnes med et tilbakemeldingsskjema hvor sluttbruker kan angi hvor fornøyd man er med service i den aktuelle butikken. Det er også mulig å legge inn en kommentar.</li>
            <li>Tilbakemeldinger på service lagres anonymt i Club Intersport og brukes til å forbedre service i butikk.</li>
          </ol>
          <p>App-push for å gi tilbakemelding på service sendes ut via 3. part leverandør OneSignal.</p>
          <p>Posisjonen til bruker lagres ikke i systemet.</p>

          <h3>Pushmeldinger via One signal</h3>
          <p>Pushmeldinger er valgfritt. De som aktiverer push kan motta informasjon fra produkteier Intersport. Klikk på push vil åpne appen.</p>
          <p>Pushmeldinger sendes også til de som har akseptert Location til kommunikasjon med beacons i butikk. Se detaljer nedenfor.</p>
          <p>Informasjonen som sendes til OneSignal innebærer data om telefonen, og ingen informasjon om enkeltpersoner. Kort oppsummert inneholder dataene følgende informasjon om telefonen</p>
          <ul>
            <li>Operativsystem (iOS eller Android)</li>
            <li>Modell</li>
            <li>Status (Om telefonen er rootet/jailbreaket) </li>
            <li>Språk</li>
            <li>Tidssone</li>
            <li>Land</li>
            <li>Versjon av appen</li>
          </ul>

          <h3>EG Norge sine løsninger ved bruk av e-handel</h3>
          <p>E-handel i app går via EG Norge sine løsninger. Handlekurv og utsjekk overføres til EG for de som velger å handle i app.</p>
          <p>Ved handel lagres hvilke produkter som er kjørt, type betaling og medlemsnummer som har utført kjøp.</p>

          <h3>Betalingsløsninger ved e-handel</h3>
          <p>Tjenesten benytter Vipps og Swedbank pay som betalingsalternativer ved utsjekk av varer.</p>

          <h3>Anonymisert tracking av sidevisninger via Matomo</h3>
          <p>Tjenesten benytter anonymisert tracking av sidevisninger via tjensten Matomo. Denne er valgfritt og kan skrus av og på på Min profil / Samtykker. Se <a href="https://matomo.org/privacy-policy/">Matomo sin privacy policy.</a></p>

          <h2>Appen ber om følgende tillatelser</h2>
          <ul>
            <li>Pushmeldinger (via OneSignal)</li>
            <li>GPS/lokasjonstjenester</li>
            <li>Location og background location</li>
            <li>Bluetooth for kommunikasjon med beacons i fysisk butikk</li>
          </ul>


          <h2>Logging av data</h2>
          <p>Tjenesten logger data i serverlogger for å kunne feilsøke problemer og forbedre tjenesten. Logging av data kan inneholde IP-adresser, informasjon om enhet, dato og annen statistikk.</p>

          <h2>Sikkerhet</h2>
          <p>Vi verdsetter at du stoler på oss for behandling av personlig informasjon. Vi jobber kontinuerlig med å beskytte tjenesten og data som er lagret. Likevel minner vi om at ingen tjeneste på internett er 100% sikret mot innbrudd eller nedetid.</p>

          <h2>Barns sikkerhet</h2>
          <p>Appen retter seg ikke mot barn under 13 år. Appen er beregnet på voksne brukere.</p>

          <h2>Endringer i personvernerklæringen</h2>
          <p>Fra tid til annen kan vi endre på denne erklæringen ved oppdatering av tjenesten. Vi anbefaler at du periodevis sjekker denne siden. Sist endring på denne siden var 05.10.2021.</p>

          <h2>Kontakt</h2>
          <p>Ta gjerne kontakt med oss om du har spørsmål:</p>
          <address>
            INTERSPORT<br />
            v/fordels- og lojalitetsprogrammet Club INTERSPORT<br />
            Postboks 384 Sentrum<br />
            0102 Oslo
          </address>
          <p>Ta gjerne kontakt med Intersport via <a href="https://www.intersport.no/hjelp/Kontakt+oss.html">kontaktskjema.</a></p>

          <p>For kontakt med Innit AS:</p>
          <address>
            Innit AS<br />
            Storhamargata 44<br />
            2317 Hamar<br />
            <tel>+47 4000 33 55</tel>
          </address>


          <h2>Lenker til app</h2>
          <p>iOS: <a target="_blank" rel="noreferrer" href="https://apps.apple.com/no/app/intersport/id1450257838">App Store</a> </p>
          <p>Android: <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=no.innit.vinnvinn">Google Play</a> </p>

        </div>

      </div>

    </div>

  );


};



export default Policy;
