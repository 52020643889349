/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonBase, Tooltip } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { ActionCreators } from "../../actions";
import { logout } from "../../actions/user";
import { bindActionCreators } from "redux";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import Requester from "../../api/Requester";
import { CSSTransition } from "react-transition-group";
import "./styles.scss";
import CustomSwitch from "../../utils/CustomSwitch";
import RulesModal from "../../components/RulesModal/RulesModal";
import UserForm from "../../components/UserForm/UserForm";
import UserInfo from "../../components/UserInfo/UserInfo";
import { arrayOf, number, func, shape, bool } from "prop-types";
import Loader from "../../components/Loader/Loader";
import {
  getAllTermsSelector,
  getSelectedTermsSelector,
  getIsAuthenticated,
} from "../../utils/selectors";
import { SET_USER, SET_USER_TERMS } from "../../actions/types";
import DeleteUserModal from "../../components/DeleteUserModal/DeleteUserModal";

const windowWidth = window.innerWidth;

// eslint-disable-next-line react/prop-types
const TermsPage = ({
  allTerms,
  aggreedTerms,
  setAllTerms,
  setAggreedTerms,
  isAuthenticated,
}) => {
  const [loading, setLoading] = useState(false);
  const [showConditions, setShowConditions] = useState(false);
  const [showTooltips, setShowToolTips] = useState([]);
  const [showUserForm, setShowUserForm] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // init styles for width of tooltips:
  // padding 40px each side
  const useStyles = makeStyles(() => ({
    customWidth: {
      maxWidth: windowWidth < 700 ? windowWidth - 80 : "none",
    },
  }));

  const classes = useStyles();

  // spot previous aggreedTerms change
  const prevAggreedTermsRef = useRef();
  useEffect(() => {
    prevAggreedTermsRef.current = aggreedTerms;
  });
  const prevAggreedTerms = prevAggreedTermsRef.current;

  useEffect(() => {
    if (!allTerms || (allTerms && allTerms.length === 0 && isAuthenticated)) {
      getAllTerms();
    }
  }, [allTerms]);

  // get approved terms on each render
  useEffect(() => {
    getApprovedTerms();
  }, []);

  useEffect(() => {
    if (
      aggreedTerms &&
      aggreedTerms.length > 0 &&
      prevAggreedTerms &&
      prevAggreedTerms.length > 0 &&
      prevAggreedTerms.length !== aggreedTerms.length &&
      isAuthenticated
    ) {
      sendTermChangeToApi();
    }
  }, [aggreedTerms]);

  const toggleShowConditions = () => {
    setShowConditions(!showConditions);
  };

  const getAllTerms = async () => {
    setLoading(true);
    try {
      await Requester.getAllTerms().then((res) => {
        setAllTerms(res);
      });
    } catch (err) {
      toast.error(err || "Klarte ikke å hente samtykker...");
    } finally {
      setLoading(false);
    }
  };

  const getApprovedTerms = async () => {
    setLoading(true);
    try {
      await Requester.getApprovedTerms().then((res) => {
        setAggreedTerms(res);
      });
    } catch (err) {
      if (err === "Feilet fornying av login token. Prøv å logge inn på nytt.") {
        toast.error(err);
        dispatch(logout());
        await Requester.logout().then(() => {
          navigate("/");
        });
      } else {
        toast.error(err || "Klarte ikke å hente samtykker...");
      }
    } finally {
      setLoading(false);
    }
  };

  const sendTermChangeToApi = async () => {
    setLoading(true);
    const payload = {
      approved_term_ids: aggreedTerms,
    };
    try {
      await Requester.updateApprovedTerms(payload).then((res) => {
        dispatch({ type: SET_USER, data: res });
        toast.success("Oppdaterte samtykkene");
        if (res?.terms && res.terms.length > 0) {
          const agreedTermsIds = res.terms.map((termItem) => termItem.id);
          dispatch({ type: SET_USER_TERMS, data: agreedTermsIds });
        }
      });
    } catch (err) {
      toast.error(err || "Klarte ikke å oppdatere samtykker...");
    } finally {
      setLoading(false);
    }
  };

  const handleTermChange = (id) => {
    if (aggreedTerms && aggreedTerms.length > 0) {
      const isChecked = aggreedTerms.indexOf(id) !== -1 ? true : false;
      if (isChecked) {
        const newArr = aggreedTerms.filter((term) => term !== id);
        // general terms must be id 3
        const generealTermsMarked = newArr.find((termId) => termId === 3);
        if (!generealTermsMarked) {
          toggleShowDeleteUserModal();
        } else {
          setAggreedTerms(newArr);
        }
      } else {
        const newArr = [...aggreedTerms, id];
        setAggreedTerms(newArr);
      }
    } else {
      setAggreedTerms([id]);
    }
  };

  const toggleToolTipOpen = (id) => {
    const isOpen = showTooltips.indexOf(id) !== -1;
    if (isOpen) {
      const newArr = showTooltips.filter((term) => term !== id);
      setShowToolTips(newArr);
    } else {
      setShowToolTips([...showTooltips, id]);
    }
  };

  const sendToUrl = () => {
    const newWindow = window.open(
      "https://form.socialboards.com/intersport",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const toggleShowUserForm = () => {
    setShowUserForm(!showUserForm);
  };

  const showTerms = () => {
    if (allTerms && allTerms.length > 0) {
      return allTerms.map((term) => {
        return (
          <div className="termWrapper" key={term.id}>
            <div className="termWrapperContainer">
              <div className="termTitle">{term.title}</div>
              <div className="termLineContainer">
                <div className="termShortText">{term.intro}</div>
              </div>
              {term.id === 3 || term.name === "general" ? (
                <div className="readMoreAndToggleContainer">
                  <ButtonBase onClick={() => toggleShowConditions()}>
                    <div className="readMoreText">Les mer</div>
                  </ButtonBase>
                  <div className="enabledToggle">
                    <CustomSwitch
                      color="primary"
                      checked={checkIfChecked(term.id)}
                      onChange={() => handleTermChange(term.id)}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <ButtonBase
                    className="readMoreTextButton"
                    onClick={() => toggleToolTipOpen(term.id)}
                  >
                    <div className="readMoreText">
                      {showTooltips.indexOf(term.id) !== -1
                        ? "Lukk"
                        : "Les mer"}
                    </div>
                  </ButtonBase>
                  <CSSTransition
                    in={showTooltips.indexOf(term.id) !== -1}
                    timeout={300}
                    className="termTextContainer"
                    unmountOnExit
                  >
                    <div dangerouslySetInnerHTML={{ __html: term.text }} />
                  </CSSTransition>
                  <div className="enabledToggle">
                    <CustomSwitch
                      color="primary"
                      checked={checkIfChecked(term.id)}
                      onChange={() => handleTermChange(term.id)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        );
      });
    }
  };

  const checkIfChecked = (id) => {
    if (aggreedTerms && aggreedTerms.length > 0) {
      const found = aggreedTerms.find((term) => term === id);
      if (found) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const toggleShowDeleteUserModal = async () => {
    setShowDeleteUserModal(!showDeleteUserModal);
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    const payload = {
      approved_term_ids: [],
    };
    try {
      await Requester.updateApprovedTerms(payload).then((res) => {
        dispatch({ type: SET_USER, data: res });
        toast.success("Oppdaterte samtykkene");
      });
    } catch (err) {
      toast.error(err || "Klarte ikke å oppdatere samtykker...");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <RulesModal
        showModal={showConditions}
        toggleShowRules={toggleShowConditions}
      />
      <DeleteUserModal
        showModal={showDeleteUserModal}
        toggleShowDeleteUserModal={toggleShowDeleteUserModal}
        handleDeleteUser={handleDeleteUser}
        loading={loading}
      />
      <Loader state={loading} cover />
      <div className="termPageContainer">
        <div className="objectWrapper">
          <div className="objectContainer">
            <h2 className="modalTitleText">Personopplysninger</h2>
          </div>
        </div>
        <div className="objectWrapper ">
          <div className="objectContainer userInfoContainer">
            <CSSTransition
              in={showUserForm}
              timeout={300}
              classNames="userForm"
              unmountOnExit
            >
              <UserForm
                toggleShowUserForm={toggleShowUserForm}
                loading={loading}
                setLoading={setLoading}
              />
            </CSSTransition>
          </div>
        </div>

        <div className="objectWrapper userInfoContainer">
          <div className="objectContainer">
            {!showUserForm ? (
              <UserInfo
                toggleShowUserForm={toggleShowUserForm}
                loading={loading}
                setLoading={setLoading}
              />
            ) : null}
          </div>
        </div>

        <div className="objectWrapper">
          <div className="objectContainer">
            <h2 className="modalTitleText">Samtykker</h2>
          </div>
        </div>
        {showTerms()}

        <div className="objectWrapper">
          <div className="objectContainer">
            <h2 className="disclaimerText">Personvern og mine rettigheter</h2>
          </div>
        </div>

        <div className="objectWrapper">
          <div className="objectContainer">
            <div className="termShortText">
              Som medlem har du en rekke krav iht. GDPR. Dette gjelder retten
              til å bli glemt/slettet, retten til portabilitet og retten til å
              be om innsyn i data som er lagret om deg.
            </div>
          </div>
        </div>

        <div className="objectWrapper">
          <div className="objectContainer">
            <ButtonBase onClick={() => sendToUrl()}>
              <div className="deleteText">
                Klikk her dersom du ønsker å kontakte oss om persondata eller
                generelle spørsmål.
              </div>
            </ButtonBase>
          </div>
        </div>
      </div>
    </div>
  );
};

TermsPage.propTypes = {
  allTerms: arrayOf(shape({})),
  aggreedTerms: arrayOf(number),
  setAllTerms: func,
  setAggreedTerms: func,
  isAuthenticated: bool.isRequired,
};

TermsPage.defualtProps = {
  allTerms: [],
  aggreedTerms: [],
  setAllTerms: () => {},
  setAggreedTerms: () => {},
};

const mapStateToProps = (state) => ({
  state: state,
  allTerms: getAllTermsSelector(state),
  aggreedTerms: getSelectedTermsSelector(state),
  isAuthenticated: getIsAuthenticated(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsPage);
