// User
export const SET_USER = "SET_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_USER_TERMS = "SET_USER_TERMS";
export const SET_ALL_TERMS = "SET_ALL_TERMS";

// Menu
export const SET_MENU = "SET_MENU";
export const SET_UNSUB_SEARCH = "SET_UNSUB_SEARCH";

// Clubs
export const SET_MY_CLUBS = "SET_MY_CLUBS";
export const SET_ALL_CLUBS = "SET_ALL_CLUBS";

//Features
export const SET_FEATURES = "SET_FEATURES";

// Screen height width
export const SET_SCREEN_HEIGHT = "SET_SCREEN_HEIGHT";
export const SET_SCREEN_WIDTH = "SET_SCREEN_WIDTH";

// Favorite store
export const SET_SHOW_FAVORITE_STORES_MODAL = "SET_SHOW_FAVORITE_STORES_MODAL";
export const SET_SHOULD_FOLLOW_STORE = "SET_SHOULD_FOLLOW_STORE";
export const SET_SUGGESTED_STORES_TO_FOLLOW = "SET_SUGGESTED_STORES_TO_FOLLOW";
export const SET_USER_FINDING_STORE_SELF = "SET_USER_FINDING_STORE_SELF";
export const SET_IS_FETCHING_SUGGESTED_STORES =
  "SET_IS_FETCHING_SUGGESTED_STORES";
export const SET_USER_FAVORITE_STORES = "SET_USER_FAVORITE_STORES";
