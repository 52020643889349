import { SET_FEATURES } from './types';

export function setFeatures(data) {
  return {
    type: SET_FEATURES,
    data
  };
}




