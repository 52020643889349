import * as UserActions from './user';
import * as MenuActions from './menu';
import * as ClubsActions from './clubs';
import * as FeaturesActions from './features';


export const ActionCreators = {
  ...UserActions,
  ...MenuActions,
  ...ClubsActions,
  ...FeaturesActions,
};
