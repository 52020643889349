import { SET_MY_CLUBS, SET_ALL_CLUBS } from './types';

export function setMyClubs(data) {
  return {
    type: SET_MY_CLUBS,
    data
  };
}


export function setAllClubs(data) {
  return {
    type: SET_ALL_CLUBS,
    data
  };
}



