import React, { useEffect, useState } from "react";
import { Modal, ButtonBase, CircularProgress, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import CancelIcon from "@mui/icons-material/Cancel";
import { bool, func, shape, arrayOf } from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import Requester from "../../api/Requester";
import "./styles.scss";
import { groupListOnFirstLetter } from "../../utils/methods";
import { setUSerFindingStoreSelf } from "../../actions/user";

const StorePickModal = ({
  showModal,
  toggleShowStores,
  myStores,
  setMyStores,
}) => {
  const dispatch = useDispatch();

  const [allStores, setAllStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedStores, setSearchedStores] = useState([]);

  const isUserFindingStoreSelf = useSelector(
    (state) => state?.userReducer?.userFindingStoreSelf
  );

  const getAllStores = async () => {
    setLoading(true);
    try {
      const res = await Requester.getMyEligibleStores();
      setAllStores(res);
    } catch {
      toast.error("Klarte ikke å hente alle tilgjengelige butikker...");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      getAllStores();
    }
  }, []);

  useEffect(() => {
    if (allStores && allStores.length > 0 && myStores?.length > 0) {
      const myStoresIds = myStores.map((item) => item.id);

      const filteredStoresNew = allStores.filter(
        (store) => !myStoresIds.includes(store.id)
      );

      setFilteredStores(filteredStoresNew);
    } else {
      setFilteredStores(allStores);
    }
  }, [myStores, allStores]);

  useEffect(() => {
    if (
      filteredStores?.length > 0 &&
      searchText?.length > 0 &&
      typeof searchText === "string"
    ) {
      const uniFormSearch = searchText.trim().toUpperCase();
      const matchingSearch = filteredStores.filter((clubItem) => {
        const uniformTitle = clubItem?.name.trim().toUpperCase();
        if (uniformTitle.includes(uniFormSearch)) {
          return true;
        }
        return false;
      });
      debounce(() => setSearchedStores(matchingSearch), 500);
      setSearchedStores(matchingSearch);
    }
  }, [filteredStores, searchText]);

  const removeFollowedStore = async (storeId) => {
    setLoading(true);
    try {
      const payload = { store_id: [storeId] };
      const res = await Requester.unfollowStore(payload);
      if (res) {
        setMyStores(res);
        setSearchText("");
      }
      toast.success("Fulgte butikker ble oppdatert");
    } catch {
      toast.error("Klarte ikke å oppdatere butikken.");
    } finally {
      setLoading(false);
    }
  };

  const updateFavoriteStores = async (storeId) => {
    setLoading(true);
    try {
      const newStoresIds = myStores.map((item) => item.id);
      newStoresIds.push(storeId);
      const payload = { store_ids: newStoresIds };
      const res = await Requester.followStores(payload);
      if (res?.length > 0) {
        setMyStores(res);
        setSearchText("");
      }
      toast.success("Fulgte butikker ble oppdatert");
    } catch {
      toast.error("Klarte ikke å oppdatere butikken.");
    } finally {
      setLoading(false);
    }
  };

  const listStoresUnder = (arr) => {
    if (arr && arr.length > 0) {
      return arr.map((store, index) => {
        const isItemLast = arr.length - 1 === index;
        return (
          <div
            key={store.id}
            className={
              isItemLast
                ? "allClubsItemContainer extraSpaceAfterLetterItems"
                : "allClubsItemContainer"
            }
          >
            <div className="availableStoreNameText">{store.name}</div>
            <ButtonBase onClick={() => updateFavoriteStores(store.id)}>
              <div className="joinClubText">Følg</div>
            </ButtonBase>
          </div>
        );
      });
    }
    return null;
  };

  const listStores = () => {
    const collectionToUse =
      searchText?.length > 0 ? searchedStores : filteredStores;
    const soretedStores = groupListOnFirstLetter(collectionToUse);
    if (soretedStores && soretedStores.length > 0) {
      return soretedStores.map((item) => {
        return (
          <div key={item.key}>
            <div className="allClubsLetter">{item.key}</div>
            {listStoresUnder(item.data)}
          </div>
        );
      });
    }
  };

  const showMyStore = () => {
    if (myStores && myStores?.length > 0) {
      return myStores.map((storeItem) => {
        return (
          <div key={storeItem.id} className="myClubsItem">
            <div className="myClubsItemTitleContainer">
              <div className="myClubsItemTitle">{storeItem.name}</div>
            </div>
            <div>
              <ButtonBase onClick={() => removeFollowedStore(storeItem.id)}>
                <DeleteIcon className="deleteClubText"></DeleteIcon>
              </ButtonBase>
            </div>
          </div>
        );
      });
    }
    return null;
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e?.target?.value);
  };

  const renderSearchField = () => {
    return (
      <TextField
        label="Søk"
        size="small"
        className="storeSearchField"
        value={searchText}
        onChange={handleSearchTextChange}
      />
    );
  };

  const handleCancelFollowStoreAction = async () => {
    const payload = { store_ids: [] };
    try {
      setLoading(true);
      await Requester.followStores(payload);
    } catch {
      toast.error(
        "Beklager, valget ditt om «Mine butikken» kunne ikke lagres på grunn av en teknisk feil. Merk at du vil bli bedt om å velge dette igjen ved neste innlogging."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClosePickModalAction = async () => {
    if (isUserFindingStoreSelf) {
      // call api and pick none of suggested stores
      await handleCancelFollowStoreAction();
      dispatch(setUSerFindingStoreSelf(false));
    }
    toggleShowStores(false);
  };

  if (showModal) {
    return (
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={showModal}
        onBackdropClick={() => toggleShowStores(false)}
      >
        <div className="rulesContainer">
          <div className="outterPickModalContainer">
            <div className="innerPickModalContainer">
              {loading ? (
                <div className="loadingContainerInClubpickModal">
                  <CircularProgress size={48} color="primary" />
                </div>
              ) : (
                <div>
                  <div>
                    <ButtonBase
                      onClick={() => handleClosePickModalAction()}
                      className="exitIconContainer"
                    >
                      <CancelIcon color="primary" />
                    </ButtonBase>
                  </div>
                  {myStores && myStores.length > 0 ? (
                    <div>
                      <div className="myClubsTitle ">Mine butikker</div>
                      <div className="myClubsListContainer">
                        {showMyStore()}
                      </div>
                    </div>
                  ) : null}
                  <div className="storeSearchAndTitleContainer">
                    {filteredStores && filteredStores.length > 0 ? (
                      <div className="availableStores">
                        Tilgjengelige butikker:
                      </div>
                    ) : null}
                    <div className="storeSearch">{renderSearchField()}</div>
                  </div>
                  {listStores()}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return null;
};

StorePickModal.propTypes = {
  toggleShowStores: func.isRequired,
  showModal: bool.isRequired,
  myStores: arrayOf(shape({})),
  setMyStores: func.isRequired,
};

StorePickModal.defualtProps = {
  myStore: {},
};

export default StorePickModal;
