import { useLocation } from 'react-router-dom';

export const getNorwegianDay = dayNumber => {
  let dayName = '';
  switch (dayNumber) {
    case 1:
      dayName = 'Mandag';
      break;
    case 2:
      dayName = 'Tirsdag';
      break;
    case 3:
      dayName = 'Onsdag';
      break;
    case 4:
      dayName = 'Torsdag';
      break;
    case 5:
      dayName = 'Fredag';
      break;
    case 6:
      dayName = 'Lørdag';
      break;
    case 7:
      dayName = 'Søndag';
      break;
    default:
      dayName = '';
  }
  return dayName;
};

export const isEmail = value => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(value);
};


export const groupListOnFirstLetter = (items = [], property = 'name') => {
  const grouppedItems = Object.entries(items).sort(([, a], [, b]) => {
    // Use toUpperCase() to ignore character casing
    const genreA = a[property].toUpperCase();
    const genreB = b[property].toUpperCase();

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }).reduce((acc, [, item]) => {
    const letterIndex = item[property].charAt(0).toUpperCase();
    acc[letterIndex] = acc[letterIndex] || [];

    acc[letterIndex].push(item);
    return acc;
  }, {});

  return Object.entries(grouppedItems).map(([key, data]) => ({
    data,
    key,
  }));
};

export const getParams = (keyString, initValue = '', isNumber) => {
  const searchParams = new URLSearchParams(useLocation().search);
  if (isNumber) {
    return parseInt(searchParams.get(keyString), 10) || initValue;
  }
  return searchParams.get(keyString) || initValue;
};
