import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";
import { func } from "prop-types";
import Requester from "../../api/Requester";
import { ActionCreators } from "../../actions";
import { TextField, Button, CircularProgress, ButtonBase } from "@mui/material";
import Storage from "../../api/Storage";
import { getIsAuthenticated } from "../../utils/selectors";
import BackgroundImage from "../../assets/background.jpg";
import Club from "../../assets/CLUB_logo.png";
import { setShouldFollowStore } from "../../actions/user";
import "./styles.scss";

const windowHeight = window.innerHeight;
const imageHeightDefault = (windowHeight / 3).toString() + "px";

const sectionStyle = {
  height: imageHeightDefault,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundImage: `url(${BackgroundImage})`,
  minHeight: "35%",
};

const SignIn = ({ setUserProfile, setFeatures }) => {
  SignIn.propTypes = {
    setUserProfile: func.isRequired,
    setFeatures: func.isRequired,
  };

  SignIn.defualtProps = {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let location = useLocation();
  // Prev Page and category refs
  const prevLocationRef = useRef();
  useEffect(() => {
    prevLocationRef.current = location;
  });
  const prevLocation = prevLocationRef.current;

  useEffect(() => {
    if (location.state !== prevLocation) {
      if (
        location.state &&
        location.state.codeReceived &&
        location.state.phone_number
      ) {
        setCodeReceived(true);
        setUsername(location.state.phone_number);
      }
    }
  }, [location]);

  const [username, setUsername] = useState("");
  const [country_code, setCounty_code] = useState("47");
  const [loading, setLoading] = useState(false);
  const [codeReceived, setCodeReceived] = useState(false);
  const [smsCode, setSmsCode] = useState("");

  const requestSMS = (event) => {
    event.preventDefault();
    setLoading(true);
    Requester.login(country_code, username)
      .then(() => {
        setCodeReceived(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error === "user_not_found") {
          toast.error("Bruker eksisterer ikke.");
        } else {
          toast.error(error || "Klarte ikke å sende SMS.");
        }
      });
  };
  const verify = () => {
    const payload = {
      country_code: country_code,
      username: username,
      token: smsCode,
      appVersion: "clubintersport",
    };

    setLoading(true);

    Requester.tokenLogin(payload)
      .then((response) => {
        Storage.set(Storage.KEYS.TOKEN, response.token)
          .then(() => {
            Requester.getMe()
              .then((meResponse) => {
                if (meResponse.should_follow_store === 1) {
                  dispatch(setShouldFollowStore(true));
                }
                Storage.set(Storage.KEYS.ME, JSON.stringify(meResponse))
                  .then(() => {
                    // store ME in redux
                    setUserProfile(meResponse);
                    navigate("/member");
                  })
                  .then(async () => {
                    try {
                      const { features } = await Requester.getFeatures();
                      setFeatures(features);
                    } catch (err) {
                      toast.error(err || "Klarte ikke å logge inn.");
                    }
                  })
                  .catch((error) => {
                    toast.error(error || "Klarte ikke å logge inn.");
                    setLoading(false);
                  });
              })
              .catch((error) => {
                toast.error(error || "Klarte ikke å logge inn.");
                setLoading(false);
              });
          })
          .catch((error) => {
            toast.error(error || "Klarte ikke å logge inn.");
            setLoading(false);
          });
      })
      .catch((error) => {
        toast.error(error || "Klarte ikke å logge inn.");
        setLoading(false);
      });
  };

  const setUser = (e) => {
    setUsername(e.target.value);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    if (codeReceived) {
      verify();
    } else {
      requestSMS(event);
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      handleOnSubmit(event);
    }
  };

  const onSmsCodeChange = (event) => {
    setSmsCode(event.target.value);
  };

  const renderButtonText = () => {
    if (codeReceived === false) {
      return "SEND KODE";
    } else if (!loading && codeReceived === true) {
      return "LOGG INN";
    } else {
      return <CircularProgress size={26} className="loader" />;
    }
  };

  const sendUserToRegister = () => {
    navigate("/register", { replace: true });
  };

  return (
    <div className="loginPageWrapper">
      <section style={sectionStyle}>
        <div className="layoutContainer">
          <div className="logoWrapUnsub">
            <div className="logoContainer">
              <ButtonBase onClick={() => navigate("/")}>
                <img src={Club} className="clubLogo" alt="Club_Intersport" />
              </ButtonBase>
            </div>
          </div>
        </div>
      </section>
      <div className="loginPageConatiner">
        <div className="loginContainer">
          <h1 className="loginHeaderText">Vis medlemskap</h1>
          <form className="smsCodeInput" onSubmit={(e) => handleOnSubmit(e)}>
            <div className="loginPhoneContainer">
              <TextField
                value={country_code}
                className="landCodeInputLogin"
                variant="outlined"
                type="number"
                disabled
                label="Landkode"
                sx={{ minWidth: 85 }}
                onChange={(e) => {
                  if (e?.target?.value || e?.target?.value === "") {
                    setCounty_code(e.target.value);
                  }
                }}
              />

              <TextField
                value={username}
                type="number"
                autoFocus
                className="loginInput"
                variant="outlined"
                label="Telefonnummer"
                onChange={(e) => {
                  setUser(e);
                }}
                onKeyPress={(e) => handleEnterPress(e)}
              />
            </div>
          </form>

          {codeReceived && (
            <form className="smsCodeInput" onSubmit={(e) => handleOnSubmit(e)}>
              <TextField
                autoFocus
                className="loginInput"
                variant="outlined"
                label="SMS-Kode"
                onChange={(e) => onSmsCodeChange(e)}
              />
            </form>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleOnSubmit(e)}
            className="submitButton"
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            {renderButtonText()}
          </Button>
        </div>
        <div className="registerLinkContainer">
          <div>Ikke medlem? Registrer deg </div>
          <ButtonBase
            onClick={() => sendUserToRegister()}
            className="linkToSignIn"
          >
            her.
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
