import React, { useState } from "react";
import {
  Modal,
  ButtonBase,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import { bool, func, shape } from "prop-types";
import Requester from "../../api/Requester";
import "./styles.scss";

const JoinClubModal = ({
  showModal,
  toggleShowModal,
  clubToJoin,
  getAllClubsGlobal,
  getMyClubsGlobal,
}) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLocalModalToggle = async () => {
    setCode("");
    toggleShowModal();
    getAllClubsGlobal();
    getMyClubsGlobal();
  };
  const handleLocalModalToggleWithoutRefresh = () => {
    setCode("");
    toggleShowModal();
  };

  const joinClub = async (event) => {
    setLoading(true);
    event.preventDefault();
    const payload = {
      codename: code,
      clubId: clubToJoin.id,
    };
    try {
      await Requester.joinToClub(payload).then(() => {
        handleLocalModalToggle();
      });
      toast.success("Klubben ble lagt til!");
    } catch (err) {
      toast.error(err || "Klarte ikke å tilknytte klubben...");
    } finally {
      setLoading(false);
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      joinClub(event);
    }
  };

  if (showModal) {
    return (
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={showModal}
        onBackdropClick={() => handleLocalModalToggleWithoutRefresh()}
        className="joinModalContainer"
      >
        <div className="joinClubContainer">
          <div className="outterJoinPickModalContainer">
            <div className="innerJoinPickModalContainer">
              <ButtonBase
                onClick={() => handleLocalModalToggleWithoutRefresh()}
                className="joinModalexitIconContainer"
              >
                <CancelIcon color="primary" />
              </ButtonBase>
              <div className="joinModalTitleContainer">
                <div className="joinModalQuestionText">Tilknytt deg til </div>
                <div className="joinModalClubTitleText">
                  {clubToJoin ? clubToJoin.name : ""}
                </div>
              </div>
              <div className="joinModalInfoText">
                Legg inn klubbkode for å opprette klubbtilknytningen
              </div>
              <div className="joinModalCodeInput">
                <TextField
                  value={code}
                  variant="outlined"
                  label="Klubbkode"
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  onKeyPress={(e) => handleEnterPress(e)}
                />
              </div>
              <div className="joinModalCodeInput">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => joinClub(e)}
                  className="submitButton"
                  sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  {loading ? (
                    <CircularProgress
                      size={26}
                      className="loader"
                    ></CircularProgress>
                  ) : (
                    "Fortsett"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  return null;
};

JoinClubModal.propTypes = {
  toggleShowModal: func.isRequired,
  showModal: bool.isRequired,
  clubToJoin: shape({}).isRequired,
  getMyClubsGlobal: func.isRequired,
  getAllClubsGlobal: func.isRequired,
};

JoinClubModal.defualtProps = {};

export default JoinClubModal;
