import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import SignIn from "../pages/SignIn/SignIn";
import SignUp from "../pages/SignUp/SignUp";
import Member from "../pages/Member/Member";
import Home from "../pages/Home/Home";
import Terms from "../pages/Terms/TermsPage";
import Coupons from "../pages/Coupons/Coupons";
import Policy from "../pages/Policy/Policy";
import CompleteUser from "../pages/CompleteUser/CompleteUser";
import UnsubLogin from "../pages/UnsubLogin/UnsubLogin";
import UnsubPage from "../pages/UnsubPage/Unsub";
import NewsletterPage from "../pages/Newsletter/Newsletter";
import FeedbackForm from "../pages/FeedbackForm/FeedbackForm";
import CheckedRoute from "./Route";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <CheckedRoute>
              <SignIn />
            </CheckedRoute>
          }
        />
        <Route
          path="/register"
          element={
            <CheckedRoute>
              <SignUp />
            </CheckedRoute>
          }
        />
        <Route path="/app-privacy-policy" element={<Policy />} />
        <Route path="/public/:id" element={<NewsletterPage />} />
        <Route path="/feedback/:id" element={<FeedbackForm />} />
        <Route
          path="/member"
          element={
            <CheckedRoute isPrivate>
              <Member />
            </CheckedRoute>
          }
        />
        <Route
          path="/complete_profile"
          element={
            <CheckedRoute isPrivate>
              <CompleteUser />
            </CheckedRoute>
          }
        />
        <Route
          path="/terms"
          element={
            <CheckedRoute isPrivate>
              <Terms />
            </CheckedRoute>
          }
        />
        <Route
          path="/coupons"
          element={
            <CheckedRoute isPrivate>
              <Coupons />
            </CheckedRoute>
          }
        />
        <Route
          path="/"
          element={
            <CheckedRoute>
              <Home />
            </CheckedRoute>
          }
        />
        <Route
          path="/unsub-login"
          element={
            <CheckedRoute isUnsubFlow isPrivate={false}>
              <UnsubLogin />
            </CheckedRoute>
          }
        />
        <Route
          path="/unsub"
          element={
            <CheckedRoute isPrivate isUnsubFlow>
              <UnsubPage />
            </CheckedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
