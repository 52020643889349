/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import BackgroundImage from "../../assets/background.jpg";
import Requester from "../../api/Requester";
import Club from "../../assets/CLUB_logo.png";
import { Button } from "@mui/material";

import "./styles.scss";

const windowHeight = window.innerHeight;
const imageHeightDefault = (windowHeight / 3).toString() + "px";

const sectionStyle = {
  height: imageHeightDefault,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundImage: `url(${BackgroundImage})`,
  minHeight: "30%",
};

const Newsletter = () => {
  let params = useParams();
  let navigate = useNavigate();

  const [htmlTemplate, setHtmlTemplate] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorFound, setErrorFound] = useState(false);

  useEffect(() => {
    if (params?.id) {
      getNewsletter(params.id);
    }
  }, [params]);

  const getNewsletter = async (id) => {
    try {
      setLoading(true);
      const { data } = await Requester.getPublicNewsletter(id);
      if (data?.html_snapshot) {
        setHtmlTemplate(data.html_snapshot);
      } else {
        setErrorFound(true);
      }
    } catch (err) {
      setErrorFound(true);
    } finally {
      setLoading(false);
    }
  };

  const renderNewsletterPageContent = () => {
    if (htmlTemplate) {
      return <div dangerouslySetInnerHTML={{ __html: htmlTemplate }} />;
    } else if (errorFound) {
      return (
        <div className="noCouponsMessage">Kan ikke finne nyhetsbrevet... </div>
      );
    } else {
      return null;
    }
  };

  if (loading) {
    return <Loader cover state={loading} />;
  }

  return (
    <div className="newsletterPageWrapper">
      <section style={sectionStyle}>
        <div className="layoutContainer">
          <div className="logoWrapUnsub">
            <div className="logoContainer">
              <img src={Club} className="clubLogo" alt="Club_Intersport" />
            </div>
          </div>
        </div>
      </section>

      <div className="newsletterPageContentContainer">
        <div className="newsletterCenterContainer">
          {renderNewsletterPageContent()}
        </div>
        <div className="newsletterPageButtonContainer">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/")}
            className="exitNewsletterButtonStyle"
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            Lukk
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
