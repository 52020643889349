export const getIsAuthenticated = state => {
  // !!(state.user.me && state.user.me.id);
  if (state && state.userReducer && state.userReducer.me && state.userReducer.me.id) {
    return true
  }
  return false;
}

export const getHasCompleteProfile = state => {
  // const userAcceptedTermIds = state?.userReducer?.aggreedTerms;
  const userProfileAcceptedTerms = state?.userReducer?.me?.terms;
  // id must be 3 for general terms
  let userAcceptedGeneralTerm = false;
  /*   if (userAcceptedTermIds?.length > 0) {
      const findAcceptedGeneralTerm = userAcceptedTermIds.find(term => term === 3);
      if (findAcceptedGeneralTerm) {
        userAcceptedGeneralTerm = true;
      }
    } */

  if (userProfileAcceptedTerms?.length > 0) {
    const findAcceptedGeneralTerm = userProfileAcceptedTerms.find(term => term.name === 'general');
    if (findAcceptedGeneralTerm) {
      userAcceptedGeneralTerm = true;
    }
  }
  return userAcceptedGeneralTerm;

}

export const getUserSelector = state => {
  if (state.userReducer && state.userReducer.me) {
    return state.userReducer.me
  }
  return null;
}

export const getMenuIndex = state => {
  if (state.menuReducer) {
    return state.menuReducer.menuIndex
  }
  return null;
}

export const getMyClubsSelector = state => {
  if (state.clubsReducer) {
    return state.clubsReducer.myClubs
  }
  return null;
}

export const getAllClubsSelector = state => {
  if (state.clubsReducer) {
    return state.clubsReducer.allClubs
  }
  return null;
}

export const getAllTermsSelector = state => {
  if (state.userReducer) {
    return state.userReducer.allTerms
  }
  return null;
}


export const getSelectedTermsSelector = state => {
  if (state.userReducer) {
    return state.userReducer.aggreedTerms
  }
  return null;
}


export const getUserTerms = state => {
  if (state.userReducer?.me?.terms) {
    return state.userReducer.me.terms
  }
  return null;
}

export const getUnsubSearchParam = state => {
  if (state.menuReducer) {
    return state.menuReducer.unsubRouteSearch
  }
  return null;
}



export const getUseClubBarcodeFlagSelector = state => {
  if (state.featuresReducer.features
    && state.featuresReducer.features['feature_use_club_barcodes']
    && state.featuresReducer.features['feature_use_club_barcodes'].enabled === true) {
    return true
  }
  return false;
}



export const getShowFavoriteStoresModalSelector = state => {
  if (state.userReducer) {
    return state.userReducer.showFavoriteStoresModal
  }
  return null;
}