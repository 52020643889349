import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * Handle device storage.
 */
export default class Storage {
    static KEYS = {
        ME: '@CIStore:me',
        TOKEN: '@CIStore:access_token',
        REFRESH: '@CIStore:refresh_token',
        TTL: '@CIStore:expires_in',
        FEATURES: '@CIStore:features',
        MEMBERSHIP_NUMBER: '@CIStore:membershipNumber',
    };

    /* Get the data from device storage */
    static async getAll() {
        try {
            return AsyncStorage.getAllKeys().then((stuff) => { console.log(stuff); }); // Could be null
        } catch (error) {
            console.log('Lagring feilet')
            return error;
        }
    }

    /* Save data to device storage */
    static async set(key, data) {
        try {
            await AsyncStorage.setItem(key, data);
        } catch (error) {
            console.log('Lagring feilet')
        }
    }

    /* Save multiple keys with data to device storage */
    static async setMultiple(data) {
        try {
            await AsyncStorage.multiSet(data);
        } catch (error) {
            console.log('Lagring feilet')
        }
    }

    /* Get the data from device storage */
    static async get(key) {
        try {
            return await AsyncStorage.getItem(key); // Could be null
        } catch (error) {
            console.log('Lagring feilet')
            return error;
        }
    }

    /* Get the data for multiple keys from device storage */
    static async getMultiple(keys) {
        try {
            return await AsyncStorage.multiGet(keys); // Could be null
        } catch (error) {
            console.log('Lagring feilet')
            return error;
        }
    }

    /* Remove the auth token from storage (logout) */
    static async remove(key) {
        try {
            return await AsyncStorage.removeItem(key);
        } catch (error) {
            console.log('Lagring feilet')
            return error;
        }
    }

    /* Remove the given keys from storage */
    static async removeMultiple(keys) {
        try {
            return await AsyncStorage.multiRemove(keys);
        } catch (error) {
            console.log('Lagring feilet')
            return error;
        }
    }

    /* Delete all local data */
    static async nuke() {
        const promises = Object.keys(Storage.KEYS).map(key => (
            Storage.remove(Storage.KEYS[key])
        ));

        return Promise.all(promises);
    }
}
