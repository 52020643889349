/**
 * All routes to API backend.
 */
export default class Routes {
  // Test 8 API
  static server =
    process.env.REACT_APP_ENV === "production"
      ? "https://api-dot-vinnvinn-prod.appspot.com"
      : "https://api-dot-vinnvinn-test.appspot.com";
  /*   static server =
    process.env.REACT_APP_ENV === "production"
      ? "https://api-dot-vinnvinn-prod.appspot.com"
      : "http://vinnvinn.api:8888"; */

  static url = `${Routes.server}/api`;

  static staticAssets = `${Routes.server}/assets`;

  static login = `${Routes.url}/login`;

  static logout = `${Routes.url}/logout`;

  static register = `${Routes.url}/register_club_intersport`;

  static register_confirm = `${Routes.url}/register/confirm`;

  static resend_verification = `${Routes.url}/resend_verification`;

  static refresh_token = `${Routes.url}/refresh_token`;

  static me = `${Routes.url}/me`;

  static complete_me = `${Routes.url}/complete_me`;

  static check_token = `${Routes.url}/check_token`;

  static token_login = `${Routes.url}/token_login`;

  static terms = `${Routes.url}/terms`;

  static term_approvals = `${Routes.url}/term_approvals`;

  static clubs = `${Routes.url}/clubs/all`;

  static myClubs = `${Routes.url}/me/clubs`;

  static features = `${Routes.url}/features`;

  static coupons = `${Routes.url}/coupons`;

  static eligibleStores = `${Routes.url}/eligible_favorite_stores`;

  static updateFavoriteStore = `${Routes.url}/me/favorite_store`;

  static publicNewsletter = `${Routes.url}/public_newsletter`;

  static publicFeedback = `${Routes.url}/public_feedback`;

  static sendFeedback = `${Routes.url}/send_feedback_form`;

  static storeName = `${Routes.url}/store_name`;

  static storeFollowSuggestion = `${Routes.url}/store_follows/suggestion`;

  static storeFollow = `${Routes.url}/store_follows`;
}
