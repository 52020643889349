/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { arrayOf, shape, string, func } from "prop-types";
import { ActionCreators } from "../../actions";
import BackgroundImage from "../../assets/background.jpg";
import Club from "../../assets/CLUB_logo.png";
import "./styles.scss";
import { SET_USER, SET_USER_TERMS } from "../../actions/types";
import Requester from "../../api/Requester";
import { getUserTerms, getUnsubSearchParam } from "../../utils/selectors";

function Unsub({ userTerms, unsubSearchParam, setUnsubUrlSearch }) {
  const windowHeight = window.innerHeight;
  const imageHeightDefault = (windowHeight / 3).toString() + "px";
  const sectionStyle = {
    height: imageHeightDefault,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundImage: `url(${BackgroundImage})`,
    minHeight: "100%",
  };

  const [loading, setLoading] = useState(false);
  const [errorOnCall, setErrorOnCall] = useState(false);
  const [unsubTerm, setUnsubTerm] = useState("");

  const search = useLocation().search;
  const navigate = useNavigate();

  const returnErrorPage = () => (
    <div className="unsubPageWrapper">
      <div className="unsubPageContainer">
        <div className="unsubPageTextContainer ">
          <h1 className="loginHeaderTextUnsub">
            Klarte ikke å oppdatere samtykker
          </h1>
          <div className="unsubInfoTextContainer">
            <div className="unsubInfoContainer">
              <div className="unsubTextLine">
                For å håndtere samtykker kan du gå til samtykker i Club
                Intersport.
              </div>
            </div>
          </div>
          <div className="unsubInfoTextContainer">
            <div className="unsubPageButtonContainer">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate("/terms")}
                className="unsubButtonStyle"
                sx={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                Gå til club Intersport
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const returnNotFoundTerm = () => (
    <div className="unsubPageWrapper">
      <div className="unsubPageContainer">
        <div className="unsubPageTextContainer ">
          <h1 className="loginHeaderTextUnsub">Kunne ikke finne samtykke</h1>
          <div className="unsubInfoTextContainer">
            <div className="unsubInfoContainer">
              <div className="unsubTextLine">
                For å håndtere samtykker kan du gå til samtykker i Club
                Intersport.
              </div>
            </div>
          </div>
          <div className="unsubInfoTextContainer">
            <div className="unsubPageButtonContainer">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate("/terms")}
                className="unsubButtonStyle"
                sx={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                Gå til club Intersport
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // eslint-disable-next-line no-unused-vars
  const handleUnsubscribeAction = async (userTermsLocal, unsubscribeTo) => {
    if (userTermsLocal?.length > 0) {
      const filteredTerms = userTermsLocal.filter(
        (term) => term.name !== unsubscribeTo
      );
      const termsToHoldIds = filteredTerms.map((term) => term.id);
      if (termsToHoldIds?.length > 0) {
        const payload = {
          approved_term_ids: termsToHoldIds,
        };
        try {
          setLoading(true);
          await Requester.updateApprovedTerms(payload).then((res) => {
            dispatch({ type: SET_USER, data: res });
            if (res?.terms && res.terms.length > 0) {
              const agreedTermsIds = res.terms.map((termItem) => termItem.id);
              dispatch({ type: SET_USER_TERMS, data: agreedTermsIds });
            }
          });
        } catch (err) {
          setErrorOnCall(true);
          toast.error(err || "Klarte ikke å oppdatere samtykker...");
        } finally {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (search) {
      // set redux
      setParamsFromUrl(search);
    } else if (unsubSearchParam) {
      // check redux for url param
      setUrlParam(unsubSearchParam);
    }
  }, [search, unsubSearchParam]);

  useEffect(() => {
    if (unsubTerm === "epost") {
      handleUnsubscribeAction(userTerms, "epost");
    }
    if (unsubTerm === "sms") {
      handleUnsubscribeAction(userTerms, "sms");
    }
  }, [unsubTerm]);

  const setParamsFromUrl = (urlToSearch) => {
    const newTermParam = new URLSearchParams(urlToSearch).get("term");
    if (newTermParam === "epost" || newTermParam === "sms") {
      // if param is OK - set redux
      setUnsubUrlSearch(search);
      // set local state
      setUnsubTerm(newTermParam);
    } else {
      // if no search param - not allowed
      navigate("/");
    }
  };

  const setUrlParam = (searchParam) => {
    navigate({ pathname: window.location.pathname, search: searchParam });
  };

  const dispatch = useDispatch();

  const renderPageContent = () => {
    if (errorOnCall) {
      return returnErrorPage();
    } else if (unsubTerm && (unsubTerm === "sms" || unsubTerm === "epost")) {
      return (
        <div className="unsubPageWrapper">
          <div className="unsubPageContainer">
            <div className="unsubPageTextContainer ">
              <h1 className="loginHeaderTextUnsub">
                Du er nå meldt av {unsubTerm}
              </h1>
              <div className="unsubInfoTextContainer">
                <div className="unsubInfoContainer">
                  {unsubTerm === "epost" ? (
                    <div className="unsubTextLine">
                      {" "}
                      For å melde deg på {unsubTerm} fra Club Intersport kan du
                      aktivere “Kampanje- epost” i “Samtykker” i ditt
                      medlemskap.
                    </div>
                  ) : (
                    <div className="unsubTextLine">
                      For å håndtere samtykker kan du gå til samtykker i Club
                      Intersport.
                    </div>
                  )}
                </div>
              </div>
              <div className="unsubInfoTextContainer">
                <div className="unsubPageButtonContainer">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("/terms")}
                    className="unsubButtonStyle"
                    sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                  >
                    Gå til club Intersport
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      returnNotFoundTerm();
    }
  };

  if (loading) {
    return (
      <div className="loadingContainerInClubpickModal">
        <CircularProgress size={48} color="primary" />
      </div>
    );
  } else {
    return (
      <div className="unsubPageOuterContainer">
        <div>
          <section style={sectionStyle}>
            <div className="layoutContainer">
              <div className="logoWrapUnsub">
                <div className="logoContainer">
                  <img src={Club} className="clubLogo" alt="Club_Intersport" />
                </div>
              </div>
            </div>
          </section>
        </div>
        {renderPageContent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userTerms: getUserTerms(state),
  unsubSearchParam: getUnsubSearchParam(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

Unsub.propTypes = {
  userTerms: arrayOf(shape({})),
  unsubSearchParam: string,
  setUnsubUrlSearch: func,
};

Unsub.defualtProps = {
  userTerms: [],
  unsubSearchParam: "",
  setUnsubUrlSearch: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Unsub);
