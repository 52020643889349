import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { ButtonBase } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel requires a loader
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { logout } from "../../actions/user";
import Requester from "../../api/Requester";
import Loader from "../../components/Loader/Loader";

const Coupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentyViewedIndexArray, setCurrentyViewedIndexArray] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getMyCoupons();
  }, []);

  useEffect(() => {
    // set index to each coupons image
    if (coupons?.length > 0) {
      const mappedIndexes = coupons.map((coupon) => ({
        id: coupon.id,
        currentImageIndex: 0,
      }));
      if (mappedIndexes?.length > 0) {
        setCurrentyViewedIndexArray(mappedIndexes);
      }
    }
  }, [coupons]);

  const getMyCoupons = async () => {
    setLoading(true);
    try {
      const res = await Requester.getMyCoupons();
      // map images from api
      const mappedCoupons = res.map((coupon) => {
        let mappedImages = [];
        if (coupon?.images && coupon.images.length > 0) {
          mappedImages = coupon.images.map((image) => ({
            url: image.url,
            id: image.id,
            couponId: coupon.id,
            productUrl: image?.images_extended?.product_url
              ? image.images_extended.product_url
              : "",
          }));
        }
        return {
          ...coupon,
          images: mappedImages,
        };
      });
      setCoupons(mappedCoupons);
    } catch (error) {
      if (
        error === "Feilet fornying av login token. Prøv å logge inn på nytt."
      ) {
        toast.error(error);
        dispatch(logout());
        await Requester.logout().then(() => {
          navigate("/");
        });
      } else if (typeof error === "string") {
        toast.error(error);
      } else {
        toast.error("Klarte ikke å hente rabattkoder...");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCurrentImageChange = (index, couponId) => {
    const updatedCurrentArray = currentyViewedIndexArray.map((coup) => {
      if (coup.id === couponId) {
        return {
          id: coup.id,
          currentImageIndex: index,
        };
      } else {
        return coup;
      }
    });
    setCurrentyViewedIndexArray(updatedCurrentArray);
  };

  const renderFirstImage = (coupon) => {
    if (coupon?.images && coupon?.images[0]) {
      return (
        <ButtonBase
          onClick={() => openProductUrl(coupon?.images[0].product_url)}
        >
          <img src={coupon?.images[0].url} className="couponImage" />
        </ButtonBase>
      );
    } else {
      return null;
    }
  };

  const openProductUrl = (url) => {
    if (!url) {
      return;
    }
    window.open(url, "_blank");
  };

  const listCoupons = () => {
    if (coupons && coupons.length > 0) {
      const _renderCustomPager = (imgArray, couponId) => {
        if (imgArray?.length > 1) {
          const currentCoupon = currentyViewedIndexArray.find(
            (coup) => coup.id === couponId
          );
          return imgArray.map((img, index) => {
            if (index === currentCoupon?.currentImageIndex) {
              return <div className="fullCircleActive" key={img.url}></div>;
            } else {
              return <div className="fullCircle" key={img.url}></div>;
            }
          });
        }
        return null;
      };

      return coupons.map((coupon) => {
        return (
          <div key={coupon.id} className="couponStyle">
            {coupon?.images?.length > 1 ? (
              // interval set to 99999 for workaround in the Carousel package
              //which makes extra scroll of images
              <Carousel
                dynamicHeight
                axis="horizontal"
                showThumbs={false}
                showStatus={false}
                autoPlay={false}
                swipeable
                showIndicators={false}
                emulateTouch
                interval={9999999999}
                onChange={(e) => handleCurrentImageChange(e, coupon.id)}
                renderItem={(props) => {
                  return <div className="cardShadow">{props}</div>;
                }}
                renderArrowPrev={(onClickHandler, hasPrev, label) => {
                  if (hasPrev) {
                    return (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        className={"arrowStyleLButton"}
                      >
                        <ArrowBackIosIcon className="arrowStyleL" />
                      </button>
                    );
                  } else {
                    return (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        className="arrowStyleLButtonDeactivated"
                      >
                        <ArrowBackIosIcon className="arrowStyleL" />
                      </button>
                    );
                  }
                }}
                renderArrowNext={(onClickHandler, hasNext, label) => {
                  if (hasNext) {
                    return (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        className={
                          "arrowStyleRButton"
                        } /* style={{ ...arrowStyleR, right: 0 }} */
                      >
                        <ArrowBackIosIcon className="arrowStyleR" />
                      </button>
                    );
                  } else {
                    return (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        className="arrowStyleRButtonDeactivated" /* style={{ ...arrowStyleR, right: 0, background: '#a2a2a2' }} */
                      >
                        <ArrowBackIosIcon className="arrowStyleR" />
                      </button>
                    );
                  }
                }}
              >
                {coupon.images.map((item) => (
                  <ButtonBase
                    onClick={() => openProductUrl(item.productUrl)}
                    key={item.id}
                  >
                    <img src={item.url} className="couponImage" />
                  </ButtonBase>
                ))}
              </Carousel>
            ) : (
              renderFirstImage(coupon)
            )}
            <div className="customPagerContainer">
              {_renderCustomPager(coupon.images, coupon.id)}
            </div>
            <div className="couponContainer">
              <p className="couponTitleText">Kode:</p>
              <div className="couponCodeText">{coupon.title}</div>
              {coupon.to_date ? (
                <div className="couponDateItem">
                  <div>Gyldig til: </div>
                  <div className="dateText">{coupon.to_date}</div>
                </div>
              ) : null}
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="noCouponsMessage">
          Ingen aktive rabattkoder funnet...{" "}
        </div>
      );
    }
  };

  const renderCouponTitle = () => {
    if (coupons && coupons.length > 0) {
      return <h2 className="couponsPageTitle">Aktive rabattkoder</h2>;
    }
    return null;
  };

  if (loading) {
    return <Loader cover state={loading} />;
  }

  return (
    <div className="couponsWrapper">
      <div className="couponsContainer">
        <div className="couponsInnerContainer ">
          <div className="innerChild">
            {renderCouponTitle()}
            <div className="couponListContainer">{listCoupons()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupons;
