import React, { useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { func, number } from "prop-types";
import Club from "../../assets/CLUB_logo.png";
import { getMenuIndex } from "../../utils/selectors";
import { ActionCreators } from "../../actions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MenuIcon from "@mui/icons-material/Menu";
import BackgroundImage from "../../assets/background.jpg";
import Requester from "../../api/Requester";
import { logout } from "../../actions/user";
import MobileMenuModal from "../../components/MobileMenuModal/MobileMenuModal";
import "./styles.scss";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      width: "100%",
      backgroundColor: "red",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    opacity: 1,
    "&:hover": {
      opacity: 1,
      color: "red",
    },
    "&:focus": {
      opacity: 1,
      color: "red",
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  padding: {
    padding: theme.spacing(3),
  },
  background: {
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "absolute",
  },
}));

const Topbar = ({ setMenu, menuIndex }) => {
  const windowHeight = window.innerHeight;
  const imageHeightDefault = (windowHeight / 3).toString() + "px";
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const sectionStyleBig = {
    height: imageHeightDefault,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundImage: `url(${BackgroundImage})`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  // Prev menu state
  const prevLocationRef = useRef();
  useEffect(() => {
    prevLocationRef.current = menuIndex;
  });
  const prevMenuIndex = prevLocationRef.current;

  useEffect(() => {
    if (prevMenuIndex !== menuIndex && typeof prevMenuIndex !== "undefined") {
      redirectUser(menuIndex);
    }
  }, [menuIndex, prevMenuIndex]);

  useEffect(() => {
    // check for previous ref - not to logout user
    redirectUser(menuIndex);
  }, [menuIndex]);

  const logoutUser = async () => {
    dispatch(logout());
    await Requester.logout().then(() => {
      navigate("/");
    });
  };

  const redirectUser = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        navigate("/member");
        break;
      case 1:
        navigate("/terms");
        break;
      case 2:
        navigate("/coupons");
        break;
      case 3:
        setMenu(0);
        logoutUser();
        break;
      default:
        navigate("/member");
    }
  };

  const handleChange = (event, newValue) => {
    setMenu(newValue);
  };

  const toggleShowMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <div className="topbarContainer">
      <MobileMenuModal
        showModal={showMobileMenu}
        toggleShowModal={toggleShowMobileMenu}
      />
      <div className="topbarWrapper">
        <div className="memberImageContainer">
          <section style={sectionStyleBig}>
            <div className="topbarItemsContainer">
              <div className={classes.root}>
                <div className={classes.background}>
                  <StyledTabs
                    value={menuIndex}
                    onChange={handleChange}
                    aria-label="styled tabs"
                  >
                    <StyledTab label="HJEM" />
                    <StyledTab label="SAMTYKKER OG PERSONVERN" />
                    <StyledTab label="RABATTKODER" />
                    <StyledTab label="LOGG UT" />
                  </StyledTabs>
                </div>
              </div>
            </div>
            <div className="logoContainerMember">
              <img
                src={Club}
                className="clubLogoMember"
                alt="Club_Intersport"
              />
            </div>
          </section>
        </div>
        <ButtonBase
          className="mobileMeniuButtonContainer"
          onClick={() => setShowMobileMenu(true)}
        >
          <MenuIcon className="mobileMenuIcon" />
          <div className="mobileMenuText">MENY</div>
        </ButtonBase>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menuIndex: getMenuIndex(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

Topbar.propTypes = {
  menuIndex: number,
  setMenu: func.isRequired,
};

Topbar.defualtProps = {
  menuIndex: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
