import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ButtonBase,
  CircularProgress,
  Checkbox,
  Button,
} from "@mui/material";
import Requester from "../../api/Requester";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { bool, arrayOf, shape, number, string, func } from "prop-types";
import {
  setShowFavoriteStoreModal,
  setUSerFindingStoreSelf,
  setUserFavoriteStores,
} from "../../actions/user";
import "./styles.scss";

const FollowStoreModal = ({ showModal, manuallyTurnOffModal }) => {
  const [selectedStoresIds, setSelectedStoresIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadDataForModal, setLoadDataForModal] = useState(true);

  const suggestedFollows = useSelector(
    (state) => state?.userReducer?.suggestedStoresToFollow
  );

  const isFetchingSuggestedStores = useSelector(
    (state) => state?.userReducer?.isFetchingSuggestedStores
  );

  const userFollowedStores = useSelector(
    (state) => state?.userReducer?.me?.store_follows
  );

  // Prev isFetchingSuggestedStores State
  const prevIsFetchingSuggestedStoresRef = useRef();
  useEffect(() => {
    prevIsFetchingSuggestedStoresRef.current = isFetchingSuggestedStores;
  });
  const prevIsFetchingSuggestedStores =
    prevIsFetchingSuggestedStoresRef.current;

  const isMultipleStoresAvailable = suggestedFollows?.length > 1;
  const isSingleStoreAvailable = suggestedFollows?.length === 1;

  useEffect(() => {
    if (prevIsFetchingSuggestedStores && !isFetchingSuggestedStores) {
      setTimeout(() => setLoadDataForModal(false), 500);
    }
  }, [
    suggestedFollows,
    isFetchingSuggestedStores,
    prevIsFetchingSuggestedStores,
  ]);

  const dispatch = useDispatch();

  const handleUserAdministerStoresAction = async () => {
    dispatch(setShowFavoriteStoreModal(true));
    dispatch(setUSerFindingStoreSelf(true));
    manuallyTurnOffModal();
    // send api answer with no options added 
    await handleSingleStoreFollowClick(true);
  };

  const handleFollowStoresClick = async () => {
    let payload = { store_ids: [] };
    if (selectedStoresIds?.length > 0) {
      payload = { store_ids: selectedStoresIds };
    }

    try {
      setLoading(true);
      const userStores = await Requester.followStores(payload);

      if (userStores?.length > 0) {
        dispatch(setUserFavoriteStores(userStores));
      }
      if (userStores?.length === 0) {
        dispatch(setUserFavoriteStores([]));
      }
      toast.success("Butikkene ble lagret som dine fulgte butikker.");
    } catch {
      toast.error(
        "Beklager, valget ditt om «Mine butikken» kunne ikke lagres på grunn av en teknisk feil. Merk at du vil bli bedt om å velge dette igjen ved neste innlogging."
      );
    } finally {
      setLoading(false);
      manuallyTurnOffModal();
    }
  };

  const handleSingleStoreFollowClick = async (isForDecline = false) => {
    let payload = { store_ids: [] };
    if (suggestedFollows[0]?.id > 0 && !isForDecline) {
      payload = { store_ids: [suggestedFollows[0]?.id] };
    }

    try {
      setLoading(true);
      const userStores = await Requester.followStores(payload);

      if (userStores?.length > 0) {
        dispatch(setUserFavoriteStores(userStores));
      }
      if (userStores?.length === 0) {
        dispatch(setUserFavoriteStores([]));
      }
      if (!isForDecline) {
        toast.success("Butikken ble lagret som din fulgte butikk.");
      }
    } catch {
      toast.error(
        "Beklager, valget ditt om «Mine butikken» kunne ikke lagres på grunn av en teknisk feil. Merk at du vil bli bedt om å velge dette igjen ved neste innlogging."
      );
    } finally {
      setLoading(false);
      manuallyTurnOffModal();
    }
  };

  const handleRenderOfStoresToPick = () => {
    if (userFollowedStores?.length > 0) {
      return (
        <div className="multipleStoresModalContainer">
          <p className="infoText">
          Basert på postnummeret i profilen din, følger du nå din/dine lokale butikker.
            Dette gjør at du får tilgang til eksklusive kampanjer og nyhetsbrev.
          </p>
          <p className="infoText-bottom">
          Du kan redigere hvilke butikker du følger ved å gå til «Mine butikker».
          </p>
          <div className="buttonWrapContainer">
            <div className="buttonsWrapModal">
              <Button
                variant="contained"
                color="primary"
              
                onClick={() => handleSingleStoreFollowClick(true)}
                className="submitButton"
                sx={{
                  fontSize: 16,
                }}
              >
                Ok, den er grei
              </Button>
              <div className="paddingButton"></div>
              <Button
                variant="contained"
                onClick={() => handleUserAdministerStoresAction()}
                color="secondary"
                disabled={loading}
                sx={{
                  fontSize: 16,
                }}
              >
               Gå til «Mine butikker»
              </Button>
            </div>
          </div>
        </div>
      );
    }
    if (isMultipleStoresAvailable) {
      const handleToggleOfMultipleStore = (storeId) => {
        if (storeId) {
          if (selectedStoresIds.includes(storeId)) {
            const filteredStores = selectedStoresIds.filter(
              (item) => item !== storeId
            );
            setSelectedStoresIds(filteredStores);
          } else {
            setSelectedStoresIds([...selectedStoresIds, storeId]);
          }
        }
      };

      const renderSuggestedStoreList = () => {
        if (isFetchingSuggestedStores) {
          return (
            <div className="suggestedStoresLoaderContainer">
              <CircularProgress size={26} className="loader" />
            </div>
          );
        }
        return suggestedFollows.map((item) => {
          return (
            <div key={item.id} className="followStoreCheckBoxContainer">
              <Checkbox
                checked={selectedStoresIds.includes(item.id)}
                color="primary"
                onChange={() => handleToggleOfMultipleStore(item?.id)}
              />
              <div className="choiceText">{item?.name}</div>
            </div>
          );
        });
      };

      return (
        <div className="multipleStoresModalContainer">
          <p className="infoText">
            Følg butikker og få tilgang til eksklusive kampanjer og nyhetsbrev.
            Basert på ditt postnummer foreslår vi at du følger en/flere av disse
            butikkene:
          </p>
          <div className="multipleStoreSelectScreen">
            {renderSuggestedStoreList()}
          </div>
          <div className="buttonWrapContainer">
            <div className="buttonsWrapModal">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleFollowStoresClick()}
                className="submitButton"
                disabled={selectedStoresIds?.length === 0}
                sx={{
                  fontSize: 16,
                }}
              >
                {loading ? (
                  <CircularProgress size={26} className="loader" />
                ) : (
                  "Følg butikk(er)"
                )}
              </Button>
              <div className="paddingButton"></div>
              <Button
                variant="contained"
                onClick={() => handleSingleStoreFollowClick(true)}
                color="secondary"
                disabled={loading}
                sx={{
                  fontSize: 16,
                }}
              >
                Ikke følg butikk
              </Button>
            </div>
          </div>
        </div>
      );
    } else if (isSingleStoreAvailable) {
      return (
        <div>
          <p className="singleStoreInfoText">
            Følg butikker og få tilgang til eksklusive kampanjer og nyhetsbrev.
            Basert på ditt postnummer foreslår vi at du følger:
          </p>
          <p className="followSingleStoreTitle">
            {suggestedFollows[0] && suggestedFollows[0]?.name
              ? suggestedFollows[0]?.name
              : null}
          </p>
          <div className="buttonWrapContainer">
            <div className="buttonsWrapModal">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSingleStoreFollowClick()}
                className="submitButton"
                sx={{
                  fontSize: 16,
                }}
              >
                {loading ? (
                  <CircularProgress size={26} className="loader" />
                ) : (
                  "Følg butikk"
                )}
              </Button>
              <div className="paddingButton"></div>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => handleSingleStoreFollowClick(true)}
                color="secondary"
                sx={{
                  fontSize: 16,
                }}
              >
                Ikke følg butikk
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p className="noStoresInFollowStoreModalText">
            Følg butikker og få tilgang til eksklusive kampanjer og nyhetsbrev.
            Gå til “Mine butikker” og finn butikkene du ønsker å følge.
          </p>
          <div className="buttonWrapContainer">
            <div className="buttonsWrapModal">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleUserAdministerStoresAction()}
                className="submitButton"
                disabled={loading}
                sx={{
                  fontSize: 16,
                }}
              >
                Finn butikk
              </Button>
              <div className="paddingButton"></div>
              <Button
                variant="contained"
                onClick={() => handleSingleStoreFollowClick(true)}
                color="secondary"
                sx={{
                  fontSize: 16,
                }}
              >
                {loading ? (
                  <CircularProgress size={26} className="loader" />
                ) : (
                  "Ikke nå"
                )}
              </Button>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Modal
      aria-labelledby="modal-follow-store"
      aria-describedby="modal-select-followed-stores"
      open={showModal}
      className="followStoreContainer"
    >
      <div className="followStoresModalContent">
        {loadDataForModal ? (
          <div className="paddingContainer-placeholder-container">
            <div className="suggestedStoresLoaderContainer">
              <CircularProgress size={26} className="loader" />
            </div>
          </div>
        ) : (
          <div className="paddingContainer">
            <ButtonBase
              onClick={() => handleSingleStoreFollowClick(true)}
              className="followStorExitIconContainer"
              disabled={loading}
            >
              <CancelIcon color="primary" className="cancelIcon" />
            </ButtonBase>
            <p className="followStoreModalTitle">Mine butikker</p>
            {handleRenderOfStoresToPick()}
          </div>
        )}
      </div>
    </Modal>
  );
};

FollowStoreModal.propTypes = {
  showModal: bool.isRequired,
  followStoreCheck: shape({
    follow_store: shape({
      should_follow: bool.isRequired,
      suggested_follows:
        arrayOf(
          shape({
            id: number,
            name: string,
          })
        ) || {},
    }),
  }),
  manuallyTurnOffModal: func.isRequired,
};

FollowStoreModal.defualtProps = {};

export default FollowStoreModal;
