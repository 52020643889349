import {
  SET_MENU,
  SET_UNSUB_SEARCH,
  SET_SCREEN_HEIGHT,
  SET_SCREEN_WIDTH,
} from "../actions/types";

const initialState = {
  // menuIndex - is menu in the topbar after login
  // unsubRouteSearch - url param for /unsub?term=xxx
  menuIndex: 0,
  unsubRouteSearch: null,
  screenHeight: 0,
  screenWidth: 0,
};

export default function menu(state = initialState, action) {
  switch (action.type) {
    case SET_MENU:
      return {
        ...state,
        menuIndex: action.data,
      };
    case SET_UNSUB_SEARCH:
      return {
        ...state,
        unsubRouteSearch: action.data,
      };
    case SET_SCREEN_HEIGHT:
      return {
        ...state,
        screenHeight: action.data,
      };
    case SET_SCREEN_WIDTH:
      return {
        ...state,
        screenWidth: action.data,
      };
    default:
      return state;
  }
}
