import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import Requester from "./api/Requester";
import Loader from "./components/Loader/Loader";
import Storage from "./api/Storage";
import { setUserProfile } from "./actions/user";
import { setScreenHeight, setScreenWidth } from "./actions/menu";
import {
  setShouldFollowStore,
  setSuggestedStoresToFollow,
  logout,
  setIsFetchingSuggestedStores,
} from "./actions/user";
import { setFeatures } from "./actions/features";
import Router from "./routes/Router";
import FollowStoreModal from "./components/FollowStoreModal/FollowStoreModal";
import {getHasCompleteProfile} from './utils/selectors'

const getHeight = () =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

function App() {
  const shouldFollowStore = useSelector(
    (state) => state?.userReducer?.shouldFollowStore
  );

  const hasUserAcceptedTerms = useSelector(getHasCompleteProfile);

  const isFollowedStoreModalShowing = useSelector(state => state?.userReducer?.showFavoriteStoresModal)

  const screenHeight = useSelector((state) => state?.menuReducer?.screenHeight);
  const screenWidth = useSelector((state) => state?.menuReducer?.screenWidth);
  const isAuth = useSelector(
    (state) => !!(state.userReducer.me && state.userReducer.me.id)
  );
  const [showSuggestStoreModal, setSuggestStoreModal] = useState(false);

  useEffect(() => {
    const authRoutes = ["/member", "/coupons", "/terms"];

    const { pathname } = window.location;
    const isCurrentRouteForAuth = authRoutes.find((item) => item === pathname);

  
    if (shouldFollowStore && isCurrentRouteForAuth && isAuth && !isFollowedStoreModalShowing && hasUserAcceptedTerms) {
      setSuggestStoreModal(true);
    } else {
      setSuggestStoreModal(false);
    }
  }, [shouldFollowStore, isAuth, isFollowedStoreModalShowing, hasUserAcceptedTerms]);

  const dispatch = useDispatch();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    if (screenHeight === 0) {
      dispatch(setScreenHeight(getHeight()));
    }
  }, [screenHeight]);

  useEffect(() => {
    if (screenWidth === 0) {
      dispatch(setScreenWidth(getWidth()));
    }
  }, [screenWidth]);

  useEffect(() => {
    const resizeListener = () => {
      dispatch(setScreenHeight(getHeight()));
      dispatch(setScreenWidth(getWidth()));
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: ["DIN-Pro", "Roboto"].join(","),
    },
    palette: {
      primary: {
        light: "#757ce8",
        main: "#11448F",
        dark: "#001e61",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#EDEDED",
        dark: "#EDEDED",
        contrastText: "#000000",
      },
    },
  });

  useEffect(() => {
    const checkToken = async () => {
      setIsChecking(true);
      try {
        const { token } = await Requester.checkToken("clubintersport");
        if (token) {
          await Storage.set(Storage.KEYS.TOKEN, token);
          Requester.getMe()
            .then(async (meResponse) => {
              await Storage.set(Storage.KEYS.ME, JSON.stringify(meResponse))
                .then(async () => {
                  dispatch(setUserProfile(meResponse));
                  dispatch(setIsFetchingSuggestedStores(true));
                  const res = await Requester.getMyFollowStoresSuggestion();

                  if (meResponse.should_follow_store === 1) {
                    dispatch(setShouldFollowStore(true));
                  }
                  if (res?.length > 0) {
                    dispatch(setSuggestedStoresToFollow(res));
                  }
                  if (res?.length === 0) {
                    dispatch(setSuggestedStoresToFollow([]));
                  }
                  setTimeout(
                    () => dispatch(setIsFetchingSuggestedStores(false)),
                    500
                  );
                })
                .catch((err) => {
                  console.log("Klarte ikke å lagre login på din telefon", err);
                  setTimeout(
                    () => dispatch(setIsFetchingSuggestedStores(false)),
                    500
                  );
                });
            })
            .then(async () => {
              try {
                const { features } = await Requester.getFeatures();
                dispatch(setFeatures(features));
              } catch (err) {
                console.log("ERR", err);
              }
            });
        } else {
          Storage.remove(Storage.KEYS.TOKEN);
        }
      } catch (error) {
        // delete user data
        if (error !== "terms_not_approved") {
          dispatch(logout());
        }
      } finally {
        setIsChecking(false);
      }
    };
    checkToken();
  }, [dispatch]);

  const manuallyTurnOffModal = () => {
    dispatch(setShouldFollowStore(false));
  };

  if (isChecking) {
    return <Loader cover state={isChecking} />;
  } else {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ToastContainer />
          {showSuggestStoreModal ? (
            <FollowStoreModal
              showModal={shouldFollowStore}
              manuallyTurnOffModal={manuallyTurnOffModal}
            />
          ) : null}
          <Router />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App;
