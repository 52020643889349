import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Club from "../../assets/CLUB_logo.png";
import { styled } from "@mui/material/styles";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";
import BackgroundImage from "../../assets/background.jpg";
import Requester from "../../api/Requester";
import "./styles.scss";
import { SvgIcon } from "@mui/material";
import { ReactComponent as StarActiveIcon } from "../../assets/star-active.svg";
import { ReactComponent as StarInactiveIcon } from "../../assets/star-inactive.svg";
import RenderCustomQuestionaire from "../../components/Feedback/RenderCustomQuestionaire";

function StarActive(props) {
  return (
    <SvgIcon viewBox="0 0 46 46" className="svgStarStyle" {...props}>
      <StarActiveIcon className="svgStarStyle" />
    </SvgIcon>
  );
}

function StarInactive(props) {
  return (
    <SvgIcon viewBox="0 0 46 46" className="svgStarStyle" {...props}>
      <StarInactiveIcon className="svgStarStyle" />
    </SvgIcon>
  );
}

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;
const imageHeightDefault = (windowHeight / 3).toString() + "px";

const findStarPadding = () => {
  if (windowWidth < 330) {
    return "1px";
  } else if (windowWidth < 700) {
    return "7px";
  } else {
    return "15px";
  }
};

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#11448F",
    paddingLeft: findStarPadding(),
    paddingRight: findStarPadding(),
  },
  "& .MuiRating-iconEmpty": {
    paddingLeft: findStarPadding(),
    paddingRight: findStarPadding(),
  },
  "& .MuiRating-iconHover": {
    color: "#11448F",
  },
});

const sectionStyle = {
  height: imageHeightDefault,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundImage: `url(${BackgroundImage})`,
  minHeight: "30%",
};

const FeedbackForm = () => {
  const [tokenId, setTokenId] = useState("");
  const [loading, setLoading] = useState(false);
  const [tokenApproved, setTokenApproved] = useState(null);
  const [rating, setRating] = useState(null);
  const [ratingText, setRatingText] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [storeId, setStoreId] = useState(null);
  const [storeName, setStoreName] = useState(null);
  const [customQuestionsArr, setCustomQuestionsArr] = useState();
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState([]);
  let params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (params?.id) {
      setTokenId(params.id);
    }
  }, [params]);

  useEffect(() => {
    if (storeId) {
      getStoreName(storeId);
    }
  }, [storeId]);

  useEffect(() => {
    if (location?.search) {
      const storeIdFromParams = new URLSearchParams(location.search).get("id");
      if (storeIdFromParams) {
        setStoreId(storeIdFromParams);
      }
    }
  }, [location]);

  useEffect(() => {
    if (tokenId) {
      checkTokenCall(tokenId);
    }
  }, [tokenId]);

  const sendFeedbackCall = async () => {
    setLoading(true);
    try {
      const payload = {
        stars: rating,
        ratingText: ratingText,
        token: tokenId,
      };

      const payloadWithMoreAnswers = {
        stars: rating,
        ratingText: ratingText,
        token: tokenId,
        answers: questionnaireAnswers,
      };
      const response = await Requester.sendFeedbackForm(
        customQuestionsArr?.length > 0 ? payloadWithMoreAnswers : payload
      );
      if (response) {
        setIsFormSubmitted(true);
        toast.success("Tilbakemeldingen er sendt!");
      } else {
        setIsFormSubmitted(false);
        toast.error("Kan ikke sende tilbakemeldingen.");
        navigate("/");
      }
    } catch (err) {
      if (typeof err === "string") {
        toast.error(err);
      } else {
        toast.error("Kan ikke sende tilbakemeldingen.");
      }
    } finally {
      setLoading(false);
    }
  };

  const checkTokenCall = async (tokenToCheck) => {
    setLoading(true);
    try {
      const response = await Requester.checkFeedbackToken(tokenToCheck);
      if (response) {
        setTokenApproved(true);
        if (response?.feedback_extensions?.length > 0) {
          setCustomQuestionsArr(response?.feedback_extensions);
          // setTokenApproved(true);
        }
        setTokenApproved(true);
      } else {
        setTokenApproved(false);
      }
    } catch {
      toast.error("Kan ikke laste nettsiden.");
      setTokenApproved(false);
    } finally {
      setLoading(false);
    }
  };

  const getStoreName = async (storeID) => {
    setLoading(true);
    try {
      const response = await Requester.getStoreName(storeID);
      if (response && response?.name) {
        setStoreName(response.name);
      }
    } catch (err) {
      console.log("ERR", err);
    } finally {
      setLoading(false);
    }
  };

  const handleTextChange = (event) => {
    setRatingText(event.target.value);
  };

  const findIfSendDisabled = () => {
    if (customQuestionsArr === undefined && rating !== null) {
      return false;
    } else if (
      customQuestionsArr?.length > 0 &&
      questionnaireAnswers?.length > 0
    ) {
      // if there is question Id in the list - there is also an answer
      const idsOfQuestionsAnswered = questionnaireAnswers.map(
        (item) => item.id
      );
      // filter out textfields
      const unansweredQuestions = customQuestionsArr.filter(
        (item) =>
          item?.question?.type !== "text" &&
          idsOfQuestionsAnswered.indexOf(item.id) === -1
      );

      if (unansweredQuestions?.length > 0) {
        return true;
      } else if (rating !== null) {
        return false;
      }
    }
    return true;
  };

  const renderPageContent = () => {
    if (loading) {
      return (
        <div className="feedbackCenterContainer">
          <CircularProgress className="loader"></CircularProgress>
        </div>
      );
    } else if (isFormSubmitted) {
      return (
        <div className="tyMessageContainer">
          <div className="tyMessageTextContainer">
            <div className="tyMessageTextStyle">
              Takk for at du hjelper oss med å bli bedre.
            </div>
            <div className="tyMessageTextStyle">
              {" "}
              Din tilbakemelding er viktig for oss!
            </div>
          </div>
          <div className="tyMessageButtonContainer">
            <Button
              onClick={() => navigate("/")}
              color="primary"
              variant="contained"
              className="sendFeedbackButton"
              sx={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              GÅ TIL CLUBINTERSPORT.NO
            </Button>
          </div>
        </div>
      );
    } else if (tokenApproved) {
      return (
        <div className="feedbackFormPageContentContainer">
          <div className="innerWrapperFeedback">
            {storeName ? (
              <div>
                Hvordan vil du vurdere ditt siste kjøp hos Intersport (
                {storeName})?
              </div>
            ) : (
              <div>Hvordan vil du vurdere ditt siste kjøp hos Intersport?*</div>
            )}
            <div className="feedbackElementWrapper">
              <div className="styledRatingWrapper">
                <StyledRating
                  defaultValue={rating}
                  name="rating_stars"
                  size="size-large"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                  emptyIcon={<StarInactive fontSize="inherit" />}
                  icon={<StarActive fontSize="inherit" />}
                />
              </div>
            </div>
            {customQuestionsArr?.length > 0 ? (
              <RenderCustomQuestionaire
                customQuestionsArr={customQuestionsArr}
                setQuestionnaireAnswers={setQuestionnaireAnswers}
                questionnaireAnswers={questionnaireAnswers}
              />
            ) : (
              <div className="feedbackElementWrapper-textfield">
                <TextField
                  id="feedback-multiline-flexible"
                  label="Legg igjen en anonym kommentar"
                  multiline
                  color="primary"
                  value={ratingText}
                  onChange={handleTextChange}
                  variant="outlined"
                  className="feedbackFormStyle"
                  minRows={5}
                />
              </div>
            )}

            <div className="feedbackElementWrapper-buttons">
              <div className="feedbackFormButtonContainer">
                <div className="feedbackButtonPLaceholder-desktop"></div>
                <div className="feedbackButtonWrapper">
                  <div className="cancelButtonContainer">
                    <Button
                      onClick={() => navigate("/")}
                      color="secondary"
                      variant="contained"
                      className="sendFeedbackButton"
                      sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      AVBRYT
                    </Button>
                  </div>
                  <div className="sendButtonContainer">
                    <Button
                      onClick={async () => sendFeedbackCall()}
                      color="primary"
                      variant="contained"
                      className="sendFeedbackButton"
                      sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                      disabled={findIfSendDisabled()}
                    >
                      SEND SVAR
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="feedbackCenterContainer">
          <div className="noFeedbackMessage">Kan ikke finne siden... </div>
        </div>
      );
    }
  };

  return (
    <div className="feedbackPageWrapper">
      <section style={sectionStyle}>
        <div className="layoutContainer">
          <div className="logoWrapUnsub">
            <div className="logoContainer">
              <img src={Club} className="clubLogo" alt="Club_Intersport" />
            </div>
          </div>
        </div>
      </section>
      <div className="feedbackPageContentContainer">{renderPageContent()}</div>
    </div>
  );
};

export default FeedbackForm;
